import {InputLabel, MenuItem, Select} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import useCallDataApi from "../../hooks/data";


export default function ConnectionData({reference, order}) {
    const [couriers, setCouriers] = useState([]);
    const [deliveryCourier, setDeliveryCourier] = useState(order?.delivery_courier || null)
    const [returnCourier, setReturnCourier] = useState(order?.return_courier || null)

    const {getData: getCouriers} = useCallDataApi("couriers")

    useEffect(() => {
        getCouriers().then(a => setCouriers(a.filter(c => c.valid)))
    }, [])


    return <form ref={reference}>
        <Stack>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="courier-select">Kiszállító futár</InputLabel>
                <Select
                    labelId="courier-select"
                    label="Kiszállító utár"
                    id="delivery_courier"
                    name="nullable-delivery_courier"
                    value={deliveryCourier}
                    onChange={e => setDeliveryCourier(e.target.value)}
                >
                    <MenuItem value=''>-</MenuItem>
                    {couriers.map(courier => (
                        <MenuItem key={courier.id} value={courier.id}>{courier.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="courier-select2">Visszaszállító futár</InputLabel>
                <Select
                    labelId="courier-select2"
                    label="Visszaszállító futár"
                    id="return_courier"
                    name="nullable-return_courier"
                    value={returnCourier}
                    onChange={e => setReturnCourier(e.target.value)}
                >
                    <MenuItem value=''>-</MenuItem>
                    {couriers.map(courier => (
                        <MenuItem key={courier.id} value={courier.id}>{courier.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    </form>;
}