import {Card, CardContent, Grid, IconButton, Stack, Typography} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";

const TourOfficeDetail = ({tourOfficeId}) => {
    const [tourOffice, setTourOffice] = useState({})
    const [contactPersons, setContactPersons] = useState([])
    const {getData: fetchTourOffice} = useCallDataApi('tour-offices')
    const {postData: postContactPerson} = useCallDataApi('contact-person')

    useEffect(() => {
        fetchTourOffice(tourOfficeId).then(t => setTourOffice(t))
        postContactPerson('get_for_tour_office', {tour_office_id: tourOfficeId}).then(c => setContactPersons(c))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sendMail = (contact) => {
        if (contact?.email) window.open(`mailto:${contact?.email}`)
    }

    const callNumber = (contact) => {
        if (contact?.phone) window.open(`tel:${contact?.phone}`)
    }

    return <>
        <Card sx={{minWidth: 350}} style={{marginBottom: '3px'}}>
            <CardContent style={{width: '100%'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Általános infó
                </Typography>
                <table className="detail-table">
                    <tbody>
                    <tr className="table-row">
                        <td>Név:</td>
                        <td>{tourOffice?.name}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Rövid név:</td>
                        <td>{tourOffice?.short_name}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Cím:</td>
                        <td>{tourOffice?.address}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Adószám:</td>
                        <td>{tourOffice?.vat_number}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Egyéb cég:</td>
                        <td>{tourOffice?.is_company ? 'igen' : 'nem'}</td>
                    </tr>
                    {/*<tr className="table-row">*/}
                    {/*    <td>Rövid bérleti díj HUF-ban:</td>*/}
                    {/*    <td>{tourOffice?.short_rent_HUF}</td>*/}
                    {/*</tr>*/}
                    {/*<tr className="table-row">*/}
                    {/*    <td>Hosszú bérleti díj HUF-ban:</td>*/}
                    {/*    <td>{tourOffice?.long_rent_HUF}</td>*/}
                    {/*</tr>*/}
                    {/*<tr className="table-row">*/}
                    {/*    <td>Rövid bérleti díj EUR-ban:</td>*/}
                    {/*    <td>{tourOffice?.short_rent_EUR}</td>*/}
                    {/*</tr>*/}
                    {/*<tr className="table-row">*/}
                    {/*    <td>Hosszú bérleti díj EUR-ban:</td>*/}
                    {/*    <td>{tourOffice?.long_rent_EUR}</td>*/}
                    {/*</tr>*/}
                    </tbody>
                </table>
            </CardContent>
        </Card>
        {contactPersons.length > 0 && <Typography variant="h5" gutterBottom marginTop={2}>Kapcsolattartók:</Typography>}
        <Grid container spacing={2}>
            {contactPersons.map(contact => <Grid key={contact.id} item xs={7} lg={6}>
                <Card sx={{maxWidth: 345}}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            {contact?.first_name} {contact?.last_name}
                        </Typography>
                        <Stack spacing={1}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <IconButton color='success' onClick={() => callNumber(contact)}><PhoneIcon/></IconButton>
                                <Typography variant="body2" color="text.secondary">{contact?.phone ? contact?.phone : '-'}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <IconButton color='success' onClick={() => sendMail(contact)}><EmailIcon/></IconButton>
                                <Typography variant="body2" color="text.secondary">{contact?.email ? contact?.email : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>)}
        </Grid>
    </>
}

export default TourOfficeDetail