import "./App.css";
import {BrowserRouter as Router, Route} from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Orders from "./pages/orders/Orders";
import TourOffice from "./pages/tourOffices/TourOffices";
import Daily from "./pages/daily/Daily";
import {createTheme, ThemeProvider} from "@mui/material";
import {amber, grey} from "@mui/material/colors";
import {useContext} from "react";
import ModeContext from "./context/ModeContext";
import TourGuides from "./pages/tourGuides/TourGuides";
import { huHU } from '@mui/material/locale';
import Reports from "./pages/reports/Reports";
import Report from "./pages/reports/Report";
import Courier from "./pages/courier/Courier";
import EmailTemplates from "./pages/emailTemplates/emailTemplates";
import OrderReturn from "./pages/orderReturn/orderReturn";
import CouponCodes from "./pages/couponCodes/CouponCodes";
import Scanner from "./pages/scanner/Scanner";



function App() {
    const {theme: mode} = useContext(ModeContext);

    const theme = createTheme({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    // palette values for light mode
                    primary: amber,
                    divider: amber[200],
                    background: {
                        default: grey[200],
                        paper: grey[200],
                    },
                    text: {
                        primary: grey[900],
                        secondary: grey[800],
                    },
                }
                : {
                    // palette values for dark mode
                    primary: {
                        main: '#AEB6BE',
                    },
                    divider: '#0A1929',
                    background: {
                        default: '#0A1929',
                        paper: '#0A1929',
                    },
                    text: {
                        primary: '#AEB6BE',
                        secondary: grey[500],
                    },
                }),
        },
    }, huHU)

    return (
        <div className="App">
            <Router>
                <ConfigProvider>
                    <AuthProvider>
                        <ThemeProvider theme={theme}>

                            <PrivateRoute component={HomePage} path="/" exact/>

                            <PrivateRoute requiredPermissions={['view_order']} component={Orders} path="/orders" exact/>

                            <PrivateRoute requiredPermissions={['view_touroffice']} component={TourOffice} path="/tour-offices" exact/>
                            <PrivateRoute requiredPermissions={['view_tourguide']} component={TourGuides} path="/tour-guides" exact/>

                            <PrivateRoute requiredPermissions={['view_order']} component={Daily} path="/daily" exact/>
                            {/*<PrivateRoute requiredPermissions={['view_order']} component={Scanner} path="/scanner" exact/>*/}
                            <PrivateRoute requiredPermissions={['view_order']} component={Courier} path="/courier" exact/>
                            <PrivateRoute requiredPermissions={['view_report']} component={Reports} path="/reports" exact/>
                            <PrivateRoute requiredPermissions={['view_report']} component={Report} path="/report/:id" exact/>
                            <PrivateRoute requiredPermissions={['view_emailtemplate']} component={EmailTemplates} path="/email-templates" exact/>
                            <PrivateRoute requiredPermissions={['view_order']} component={OrderReturn} path="/order-return" exact/>
                            <PrivateRoute requiredPermissions={['view_couponcode']} component={CouponCodes} path="/coupon-codes" exact/>

                            <Route component={LoginPage} path="/login"/>
                        </ThemeProvider>
                    </AuthProvider>
                </ConfigProvider>
            </Router>
        </div>
    );
}

export default App;
