import {Button, Stack, Typography, useTheme} from "@mui/material";
import InvoiceGeneralData from "./invoiceGeneralData";
import InvoiceItemData from "./invoiceItemData";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import DinoDialog from "../../../components/Dialog";
import {cleanData} from "../../../utils/FormUtils";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {generateUUID} from "../../../utils/utils";
import InvoiceItem from "./InvoiceItem";
import {thousandSeparator} from "../../../utils/NumberUtils";
import {currencies, unitPriceTypes, units, vat} from "../../../utils/enums";
import CouponCodeDetail from "../../couponCodes/CouponCodeDetail";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";

const ComposeInvoice = ({referenceGeneral, referenceItems, obj}) => {
    const theme = useTheme()
    const [current, setCurrent] = useState(-1)
    const [orderedBy, setOrderedBy] = useState({
        long_rent_EUR: 1.5,
        short_rent_EUR: 1.75,
        long_rent_HUF: 430,
        short_rent_HUF: 480,
    })
    const [loading, setLoading] = useState(false)
    const [discount, setDiscount] = useState(null)
    const [sharedValues, setSharedValues] = useState({
        currency: obj.currency
    })
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const {getData} = useCallDataApi('coupon-codes')

    const {getData: fetchUsers} = useCallDataApi('users')

    const getUnitPrice = () => {
        const rent_length = obj?.short_rent ? 'short' : 'long'
        if (orderedBy && obj?.currency) {
            return orderedBy[`${rent_length}_rent_${obj?.currency}`]
        }
        if (obj?.currency === currencies.EUR) return obj?.short_rent ? orderedBy?.short_rent_EUR : orderedBy?.long_rent_EUR
        return obj?.short_rent ? orderedBy?.short_rent_HUF : orderedBy?.long_rent_HUF
    }

    const [items, setItems] = useState([{
        uid: generateUUID(),
        name: "Headset bérlés",
        unit_price: getUnitPrice().toString(),
        unit_price_type: unitPriceTypes.NETTO,
        unit: units.PIECE,
        quantity: (parseInt(obj?.order_rx) || 1) * (obj?.days || 1),
        vat: vat.NORMAL,
        comment: `Dátumok: ${obj?.delivery_date || '-'} - ${obj?.return_date || '-'}`
    }])

    useEffect(() => {
        if (obj?.discount) {
            setLoading(true)
            getData(obj?.discount).then(d => setDiscount(d)).finally(() => setLoading(false))
        }
        if (obj?.ordered_by) {
            setLoading(true)
            fetchUsers(obj?.ordered_by).then(u => setOrderedBy(u)).finally(() => setLoading(false))
        }
    }, [])

    const itemForm = useRef(null)

    const findItem = uuid => items.find(item => item?.uid === uuid)

    const addItem = data => setItems([...items, {...data, uid: generateUUID()}])

    const editItem = (uid, newData) => setItems(items.map(item => item.uid === uid ? {...item, ...newData} : item))

    const deleteItem = uid => setItems(items.filter(item => item.uid !== uid))

    const getItems = () => items.map(({ uid, ...rest }) => rest)

    useImperativeHandle(referenceItems, () => ({
        getItems
    }))

    const handleEdit = uid => {
        setCurrent(uid)
        setEditOpen(true)
    }

    const handleDelete = uid => {
        setCurrent(uid)
        setDeleteOpen(true)
    }

    const calculateTotal = () => items.reduce((total, { quantity, unit_price }) => total + (quantity * unit_price), 0)

    return <Stack spacing={2}>
        <Stack spacing={2} sx={{border: `1px solid ${theme.palette.primary.main}`, borderRadius: 4, padding: 3}}>
            <Typography variant='h5'>Általános adatok:</Typography>
            <InvoiceGeneralData reference={referenceGeneral} obj={obj} sharedValues={sharedValues} setSharedValues={setSharedValues}/>
        </Stack>

        {discount && <Stack spacing={2} sx={{border: `1px solid ${theme.palette.primary.main}`, borderRadius: 4, padding: 3}}>
            <Typography variant='h5'>Kedvezmény:</Typography>
            <CouponCodeDetail coupon={discount}/>
        </Stack>}

        <Stack spacing={2} sx={{border: `1px solid ${theme.palette.primary.main}`, borderRadius: 4, padding: 3}}>
            <Typography variant='h5'>Tételek:</Typography>
            {items.map(item => <InvoiceItem
                key={item.uid}
                item={item}
                edit={() => handleEdit(item.uid)}
                delete={() => handleDelete(item.uid)}
                sharedValues={sharedValues}
                setSharedValues={setSharedValues}
            />)}
            <Typography variant='h5'>Összesen: {thousandSeparator(calculateTotal())} {sharedValues?.currency}</Typography>
            <Button endIcon={<AddCircleRoundedIcon/>} variant='outlined' color='success' onClick={() => setOpen(true)}>Tétel hozzáadása</Button>
        </Stack>


        <DinoDialog
            handleClose={() => setOpen(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined'
                        onClick={() => setOpen(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    const data = cleanData(itemForm.current.getData())
                    if (Object.entries(data).length > 0) {
                        addItem(data)
                        setOpen(false)
                    }
                }} endIcon={<SaveRoundedIcon/>}>Mentés</Button>
            </Stack>}
            open={open}
            title='Tétel hozzáadása'
            subtitle='Tétel hozzáadása'>
            <InvoiceItemData reference={itemForm}/>
        </DinoDialog>


        <DinoDialog
            handleClose={() => setEditOpen(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => setEditOpen(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    const data = cleanData(itemForm.current.getData())
                    if (Object.entries(data).length > 0) {
                        editItem(current, data)
                        setEditOpen(false)
                    }
                }} endIcon={<EditRoundedIcon/>}>Szerkesztés</Button>
            </Stack>}
            open={editOpen}
            title='Tétel szerkesztése'
            subtitle='Tétel szerkesztése'>
            <InvoiceItemData reference={itemForm} obj={findItem(current)}/>
        </DinoDialog>


        <DinoDialog
            handleClose={() => setDeleteOpen(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => setDeleteOpen(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='error' onClick={() => {
                    deleteItem(current)
                    setDeleteOpen(false)
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>}
            open={deleteOpen}
            title='Tétel törlése'
            subtitle='Ez a művelet nem vonható vissza!'
        />

        <Loading isLoading={loading}/>
    </Stack>
}

export default ComposeInvoice