import {format} from "date-fns";
import {
    Pending,
    Cancelled,
    Confirmed,
    CourierOrdered,
    Delivered,
    DNPrinted,
    OnTheWayOut,
    Returned,
    Returning
} from "../components/States";
import React from "react";


const formatHuman = (date) => {
    try {
        return format(date, 'yyyy-MM-dd')
    } catch (e) {
        return null
    }
}

const determineAddress = addressString => {
    try {
        // Try to parse the addressString as JSON
        // const parsedAddress = JSON.parse(addressString);

        return addressString?.address_com
    } catch (e) {
        // If parsing fails, return the addressString as it is
        return addressString;
    }
}

const generateUUID = () => {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

const daysBetween = (date1, date2) => {
    const differenceMs = Math.abs(date2 - date1)
    // Convert milliseconds to days
    return Math.ceil(differenceMs / (1000 * 60 * 60 * 24))
}

const orderStatusMap = {
    Pending: <Pending/>,
    Confirmed: <Confirmed/>,
    'DN Printed': <DNPrinted/>,
    Delivered: <Delivered/>,
    'Courier ordered': <CourierOrdered/>,
    Returning: <Returning/>,
    Returned: <Returned/>,
    Cancelled: <Cancelled/>,
    'ON THE WAY OUT': <OnTheWayOut/>,
}

export {formatHuman, determineAddress, generateUUID, daysBetween, orderStatusMap}