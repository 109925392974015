import {
    InputLabel,
    MenuItem,
    Stack,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    Box,
} from "@mui/material";
import React, {useState, useEffect, useRef} from "react";
import useCallDataApi from "../../hooks/data";
import AddIcon from '@mui/icons-material/Add';
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingFrom";
import CustomTextField from "../../components/formValidation/CustomTextField";
import CustomSelect from "../../components/formValidation/CustomSelect";
import {
    currencies,
    deliveryFormats,
    deliveryPeriods,
    deliveryTypes,
    invoiceTypes,
    orderStatuses,
    programs,
} from "../../utils/enums";
import CustomAutocomplete from "../../components/formValidation/CustomAutocomplete";
import CustomDatePicker from "../../components/formValidation/CustomDatePicker";
import GoogleMapsAutocomplete from "../../components/PlacesAutocomplete2";
import Map from "../../components/Map";
import {Marker} from "@react-google-maps/api";
import {validateNonNegativeNumber, validateNumber} from "../../components/formValidation/validators";
import Loading from "../../components/Loading";
import DinoDialog from "../../components/Dialog";
import NewTourGuideData from "../tourGuides/NewTourGuideData";
import NewTourOfficeData from "../tourOffices/NewTourOfficeData";
import {useSnackbar} from "notistack";
import {formatHuman, generateUUID} from "../../utils/utils";


const OrderForm = ({reference, order}) => {
    const [loading, setLoading] = useState({
        order_loading: false,
        tour_office_loading: false,
        items_loading: false,
        ordered_by_loading: false
    });
    const [isTourGuideDialogOpen, toggleTourGuideDialog] = useState(false)
    const [isTourOfficeDialogOpen, toggleTourOfficeDialog] = useState(false)
    const [receiverStock, setReceiverStock] = useState({})
    const newTourGuideForm = useRef(null);
    const newTourOfficeForm = useRef(null);
    const {enqueueSnackbar} = useSnackbar()

    const [users, setUsers] = useState([]);
    const [tourOffices, setTourOffices] = useState([]);
    const [contactPersons, setContactPersons] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [tourGuides, setTourGuides] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [transmitters, setTransmitters] = useState([]);
    const [onlyHungary, setOnlyHungary] = useState(true);

    const {getData: fetchOrders} = useCallDataApi("orders");
    const {getData: fetchUsers} = useCallDataApi("users");
    const {getData: fetchReceivers} = useCallDataApi("receivers");
    const {getData: fetchTransmitters} = useCallDataApi("transmitters");
    const {
        createData: createTourGuide,
        getData: fetchTourGuideData,
        postData: postTourGuide
    } = useCallDataApi("tour-guides");
    const {createData: createTourOffice, postData: postTourOffice} = useCallDataApi("tour-offices");
    const {postData: postContactPersons} = useCallDataApi("contact-person");
    const {getData: fetchCouriers} = useCallDataApi("couriers");


    const [formState, setFormState] = useState({
        order_status: order?.order_status || orderStatuses.Confirmed,
        ordered_by: order?.ordered_by || null,
        tour_type: order?.tour_type || programs.Budapest,
        tour_office: order?.tour_office || null,
        tour_code: order?.tour_code || '',
        contact_person: order?.contact_person || null,
        delivery_courier: order?.delivery_courier || '',
        return_courier: order?.return_courier || '',
        delivery_date: order?.delivery_date || formatHuman(new Date()),
        return_date: order?.return_date || formatHuman(new Date()),
        order_rx: order?.order_rx || null,
        order_tx: order?.order_tx || null,
        sent_rx: order?.sent_rx || null,
        sent_tx: order?.sent_tx || null,
        receiver: order?.receiver || '',
        transmitter: order?.transmitter || '',
        delivery_address: order?.delivery_address || {address_com: '', coordinates: {lat: 0, lng: 0}, address: ''},
        return_address: order?.return_address || {address_com: '', coordinates: {lat: 0, lng: 0}, address: ''},
        delivery_time: order?.delivery_time || null,
        delivery_period: order?.delivery_period || '',
        days_before: order?.days_before || 0,
        days_after: order?.days_after || 0,
        return_time: order?.return_time || null,
        return_period: order?.return_period || '',
        tour_guide: order?.tour_guide || null,
        short_rent: order?.short_rent || false,
        delivery_format: order?.delivery_format || deliveryFormats.Backpack,
        delivery_type: order?.delivery_type || deliveryTypes.HAND,
        return_type: order?.return_type || deliveryTypes.HAND,
        DHL_delivery_number: order?.DHL_delivery_number || '',
        DHL_order: order?.DHL_order || '',
        DHL_return_number: order?.DHL_return_number || '',
        DHL_order_back: order?.DHL_order_back || '',
        invoice: order?.invoice || invoiceTypes.InvoiceBankTransfer,
        returned_rx: order?.returned_rx || null,
        returned_tx: order?.returned_tx || null,
        extra_delivery_price: order?.extra_delivery_price || null,
        agreed_price: order?.agreed_price || null,
        invoice_number: order?.invoice_number || '',
        currency: order?.currency || currencies.HUF,
        comment: order?.comment || '',
        internal_comment: order?.internal_comment || ''
    });

    const [errors, setErrors] = useState({
        order_status: false,
        ordered_by: false,
        tour_type: false,
        tour_office: false,
        contact_person: false,
        tour_code: false,
        delivery_courier: false,
        return_courier: false,
        delivery_date: false,
        return_date: false,
        order_rx: false,
        order_tx: false,
        sent_rx: false,
        sent_tx: false,
        returned_rx: false,
        returned_tx: false,
        receiver: false,
        transmitter: false,
        delivery_address: false,
        return_address: false,
        delivery_time: false,
        delivery_period: false,
        days_before: false,
        days_after: false,
        return_time: false,
        return_period: false,
        tour_guide: false,
        short_rent: false,
        delivery_format: false,
        delivery_type: false,
        return_type: false,
        DHL_delivery_number: false,
        DHL_order: false,
        DHL_return_number: false,
        DHL_order_back: false,
        invoice: false,
        invoice_number: false,
        extra_delivery_price: false,
        agreed_price: false,
        currency: false,
        comment: false,
        internal_comment: false,
    })

    
    const validators = {
        order_status: [],
        ordered_by: [],
        tour_type: [],
        tour_office: [],
        contact_person: [],
        tour_code: [],
        delivery_courier: [],
        return_courier: [],
        delivery_date: [],
        return_date: [],
        order_rx: [validateNumber, validateNonNegativeNumber],
        order_tx: [validateNumber, validateNonNegativeNumber],
        sent_rx: [validateNumber, validateNonNegativeNumber],
        sent_tx: [validateNumber, validateNonNegativeNumber],
        returned_rx: [validateNumber, validateNonNegativeNumber],
        returned_tx: [validateNumber, validateNonNegativeNumber],
        receiver: [],
        transmitter: [],
        delivery_address: [],
        return_address: [],
        delivery_time: [],
        delivery_period: [],
        days_before: [validateNumber, validateNonNegativeNumber],
        days_after: [validateNumber, validateNonNegativeNumber],
        agreed_price: [validateNumber, validateNonNegativeNumber],
        extra_delivery_price: [validateNumber, validateNonNegativeNumber],
        return_time: [],
        return_period: [],
        tour_guide: [],
        short_rent: [],
        delivery_format: [],
        delivery_type: [],
        return_type: [],
        DHL_delivery_number: [],
        DHL_order: [],
        DHL_return_number: [],
        DHL_order_back: [],
        invoice: [],
        invoice_number: [],
        currency: [],
        comment: [],
        internal_comment: [],
    }


    useEffect(() => {
        if (order) {
            setLoading({...loading, order_loading: true})
            fetchOrders(order?.id).then(o => {
                const {
                    id,
                    order_number,
                    days,
                    price,
                    pprice,
                    closed,
                    valid,
                    current_date,
                    last_modified,
                    actual_delivery_date,
                    actual_return_date,
                    invoice_type,
                    color,
                    payed,
                    discount,
                    ...rest
                } = o;
                setFormState(rest);
            }).finally(() => setLoading({...loading, order_loading: false}))
        }
    }, [])

    useEffect(() => {
        if (formState?.tour_office) {
            setLoading({...loading, tour_office_loading: true})
            postContactPersons('get_for_tour_office', {tour_office_id: formState?.tour_office})
                .then(c => setContactPersons(c))
                .finally(() => setLoading({...loading, tour_office_loading: false}))
        } else {
            setContactPersons([])
            setFormState(prev => ({...formState, contact_person: null}))
        }
    }, [formState?.tour_office])

    useEffect(() => {
        setLoading({...loading, items_loading: true})
        Promise.all([
            fetchTourGuideData(),
            fetchOrders("receivers"),
            fetchTransmitters(),
            fetchReceivers(),
            fetchCouriers(),
            fetchUsers()
        ]).then(([allTourGuides, receivers, transmitters, receiversData, couriers, users]) => {
            setTourGuides(allTourGuides)
            setReceiverStock(receivers)
            setTransmitters(transmitters)
            setReceivers(receiversData)
            setCouriers(couriers)
            setUsers(users)
        }).finally(() => setLoading({...loading, items_loading: false}))
    }, [])

    useEffect(() => {
        setLoading({...loading, ordered_by_loading: true}); // Start loading
        Promise.all([
            postTourOffice('get_for_user', {user_id: formState?.ordered_by}),
            postTourGuide('get_for_user', {user_id: formState?.ordered_by})
        ]).then(([allTourOffices, allTourGuides]) => {
            setTourOffices(allTourOffices);
            setTourGuides(allTourGuides);
        }).finally(() => setLoading({...loading, ordered_by_loading: false}));
    }, [formState?.ordered_by]);


    const addTourOffice = (data) => {
        setLoading({...loading, tour_office_loading: true})
        createTourOffice(data).then(newTourOffice => {
            setTourOffices([...tourOffices, newTourOffice])
            enqueueSnackbar('Megrendelő mentve!', {variant: 'success'})
        }).finally(() => setLoading({...loading, tour_office_loading: false}))
    }

    const addTourGuide = (data) => {
        setLoading({...loading, tour_office_loading: true})
        createTourGuide(data).then(newTourGuide => {
            setTourGuides(tourGuide => [...tourGuide, newTourGuide])
            enqueueSnackbar('Csoportkísérő mentve!', {variant: 'success'})
        }).finally(() => setLoading({...loading, tour_office_loading: false}))
    }

    return <ErrorHandlingForm reference={reference} errors={errors} setErrors={setErrors} data={formState}
                              setData={setFormState} validators={validators}>
        <Stack spacing={2}>

            <Typography variant="h6" gutterBottom>Általános infó</Typography>


            <CustomTextField
                multiline
                rows={3}
                name="comment"
                label="Megjegyzés"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <CustomTextField
                multiline
                rows={3}
                name="internal_comment"
                label="Belsős megjegyzés"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <FormControl>
                <InputLabel id="order-statuses">Állapot</InputLabel>
                <CustomSelect
                    labelId="order-statuses"
                    label="Állapot"
                    id="order_status"
                    name="order_status"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    {Object.entries(orderStatuses).map(([key, value]) => (
                        <MenuItem key={key} value={value}>{value}</MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>


            <FormControl>
                <CustomAutocomplete
                    options={users}
                    getOptionLabel={(option) => {
                        if (typeof option === "number") {
                            const to = users.find(t => t?.id === option)
                            return to?.email || ""
                        }
                        return option?.email || ""
                    }}
                    label='Partner e-mail'
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id || generateUUID()}>
                            {option?.email}
                        </li>
                    )}
                    fieldToUse='id'
                    name="ordered_by"
                    data={formState}
                    sideEffect={() => setFormState(prev => ({...prev, tour_office: null}))}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />
            </FormControl>


            <FormControl>
                <InputLabel id="tour-types">Program</InputLabel>
                <CustomSelect
                    labelId="tour-types"
                    label="Program"
                    id="tour_type"
                    name="tour_type"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    <MenuItem value=''>-</MenuItem>
                    {Object.entries(programs).map(([key, value]) => <MenuItem key={key}
                                                                              value={value}>{value}</MenuItem>)}
                </CustomSelect>
            </FormControl>


            <FormControl>
                <CustomAutocomplete
                    options={tourOffices}
                    getOptionLabel={(option) => {
                        if (typeof option === "number") {
                            const to = tourOffices.find(t => t?.id === option)
                            return to?.name || to?.name_short || ""
                        }
                        return option?.name || option?.short_name || ""
                    }}
                    label='Megrendelő'
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id || generateUUID()}>
                            {option?.name || option?.short_name}
                        </li>
                    )}
                    fieldToUse='id'
                    name="tour_office"
                    sideEffect={() => setFormState(prev => ({...prev, contact_person: null}))}
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />
            </FormControl>

            <Button sx={{'marginTop': 1}} onClick={() => toggleTourOfficeDialog(true)} variant="outlined"
                    endIcon={<AddIcon/>}
                    color="success">Megrendelő</Button>


            {contactPersons.length > 0 && (
                <FormControl>
                    <CustomAutocomplete
                        options={contactPersons}
                        getOptionLabel={(option) => {
                            if (typeof option === "number") {
                                const c = contactPersons.find(t => t?.id === option)
                                return `${c.first_name} ${c.last_name} ${c?.phone}` || ""
                            }
                            return option?.name || ""
                        }}
                        label='Kapcsolattartó'
                        renderOption={(props, option) => (
                            <li {...props} key={option?.id || generateUUID()}>
                                {`${option.first_name} ${option.last_name} ${option?.phone}`}
                            </li>
                        )}
                        fieldToUse='id'
                        name="contact_person"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    />
                </FormControl>
            )}


            <CustomTextField
                name="tour_code"
                label="Megnevezés"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <Typography variant="h6" gutterBottom>Kiszállítás adatok</Typography>
            <Stack spacing={2} direction='row'>

                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="delivery-courier">Kiszállító futár</InputLabel>
                    <CustomSelect
                        labelId="delivery-courier"
                        label="Kiszállító futár"
                        name="delivery_courier"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value={null}>-</MenuItem>
                        {couriers.map(r => (
                            <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>


                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="return-courier">Visszaszállító futár</InputLabel>
                    <CustomSelect
                        labelId="return-courier"
                        label="Visszaszállító futár"
                        name="return_courier"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value={null}>-</MenuItem>
                        {couriers.map(r => (
                            <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>

            </Stack>


            <CustomDatePicker
                label="Oda dátum"
                inputFormat="yyyy-MM-dd"
                mask="____-__-__"
                name="delivery_date"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                sideEffect={newValue => {
                    const oldReturnDate = new Date(formState?.return_date);
                    if (oldReturnDate < newValue) {
                        const returnDate = formatHuman(newValue);
                        setFormState(prev => ({...prev, return_date: returnDate}))
                    }
                }}
            />


            <CustomDatePicker
                label="Vissza dátum"
                inputFormat="yyyy-MM-dd"
                mask="____-__-__"
                name="return_date"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

            <Stack direction='row' spacing={2}>


                <CustomTextField
                    sx={{width: '100%'}}
                    name="order_rx"
                    label="Rendelt vevők száma"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    nullable
                />


                <CustomTextField
                    sx={{width: '100%'}}
                    name="order_tx"
                    label="Rendelt ádók száma"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    nullable
                />


                <CustomTextField
                    sx={{width: '100%'}}
                    name="sent_rx"
                    label="Kiküldött vevők száma"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    nullable
                />


                <CustomTextField
                    sx={{width: '100%'}}
                    name="sent_tx"
                    label="Kiküldött adók száma"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    nullable
                />

            </Stack>

            <Stack spacing={2} direction='row'>

                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="receiver-color">Vevő szín</InputLabel>
                    <CustomSelect
                        labelId="receiver-color"
                        label="Vevő szín"
                        name="receiver"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value={null}>-</MenuItem>
                        {receivers.map(r => <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>)}
                    </CustomSelect>
                </FormControl>


                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="transmitter-color">Adó szín</InputLabel>
                    <CustomSelect
                        labelId="transmitter-color"
                        label="Adó szín"
                        name="transmitter"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value={null}>-</MenuItem>
                        {transmitters.map(r => <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>)}
                    </CustomSelect>
                </FormControl>

            </Stack>


            <Stack spacing={2}>
                <FormControlLabel control={<Checkbox checked={onlyHungary} onChange={() => setOnlyHungary(prev => !prev)}/>} label="Csak magyar címek" />
                <GoogleMapsAutocomplete
                    label='Oda cím'
                    onlyHungary={onlyHungary}
                    setAddress={address => setFormState({...formState, delivery_address: address})}
                    value={formState?.delivery_address?.address_com}/>


                <Box sx={{width: '50%'}}>
                    <Map center={formState?.delivery_address?.coordinates}>
                        {formState?.delivery_address?.coordinates &&
                            <Marker position={formState?.delivery_address?.coordinates}/>}
                    </Map>
                </Box>
            </Stack>


            <Stack direction='row' spacing={2}>
                <CustomTextField
                    name='delivery_time'
                    label="Szállítási idő"
                    type="time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{width: '100%'}}
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />


                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="deivery_period_selector">Oda mikor</InputLabel>
                    <CustomSelect
                        labelId="deivery_period_selector"
                        label="Mikor"
                        name="delivery_period"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value=''>-</MenuItem>
                        {Object.entries(deliveryPeriods).map(([key, value]) => <MenuItem key={key}
                                                                                         value={value}>{value}</MenuItem>)}
                    </CustomSelect>
                </FormControl>


                <CustomTextField
                    sx={{width: '100%'}}
                    name="days_before"
                    label="Előtte"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    nullable
                />

            </Stack>


            <Stack spacing={2}>
                <GoogleMapsAutocomplete
                    label='Vissza cím'
                    onlyHungary={onlyHungary}
                    setAddress={address => setFormState({...formState, return_address: address})}
                    value={formState?.return_address?.address_com}/>
                <Box sx={{width: '50%'}}>
                    <Map center={formState?.return_address?.coordinates}>
                        {formState?.return_address?.coordinates &&
                            <Marker position={formState?.return_address?.coordinates}/>}
                    </Map>
                </Box>
            </Stack>


            <Stack direction='row' spacing={2}>
                <CustomTextField
                    name='return_time'
                    label="Vissza idő"
                    type="time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{width: '100%'}}
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />


                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="return_period_selector">Vissza mikor</InputLabel>
                    <CustomSelect
                        labelId="return_period_selector"
                        label="Vissza mikor"
                        name="return_period"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value=''>-</MenuItem>
                        {Object.entries(deliveryPeriods).map(([key, value]) => <MenuItem key={key}
                                                                                         value={value}>{value}</MenuItem>)}
                    </CustomSelect>
                </FormControl>


                <CustomTextField
                    sx={{width: '100%'}}
                    name="days_after"
                    label="Utána"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    nullable
                />

            </Stack>


            <FormControl>
                <CustomAutocomplete
                    options={tourGuides}
                    getOptionLabel={(option) => {
                        if (typeof option === "number") {
                            const to = tourGuides.find(t => t?.id === option)
                            return to ? `${to.first_name} ${to.last_name} ${to?.phone}` : ""
                        }
                        return option ? `${option.first_name} ${option.last_name} ${option?.phone}` : ""
                    }}
                    label='Csoportkísérő'
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id || generateUUID()}>
                            {`${option.first_name} ${option.last_name} ${option?.phone}` || "-"}
                        </li>
                    )}
                    fieldToUse='id'
                    name="tour_guide"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />
            </FormControl>


            <Button style={{'marginTop': '10px'}} onClick={() => {
                toggleTourGuideDialog(true)
            }} variant="outlined" endIcon={<AddIcon/>}
                    color="success">Csoportkísérő</Button>


            <FormControl>
                <FormControlLabel
                    label="Rövid bérlés?"
                    control={
                        <Checkbox
                            id="short_rent"
                            checked={formState?.short_rent}
                            value={formState?.short_rent}
                            onClick={() => setFormState({...formState, short_rent: !formState?.short_rent})}
                        />
                    }
                />
            </FormControl>

            <Stack direction='row' spacing={2}>

                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="delivery-format">Kiszállítási formátum</InputLabel>
                    <CustomSelect
                        labelId="delivery-format"
                        label="Delivery Format"
                        name="delivery_format"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value=''>-</MenuItem>
                        {Object.entries(deliveryFormats).map(([key, value]) => <MenuItem key={key}
                                                                                         value={value}>{value}</MenuItem>)}
                    </CustomSelect>
                </FormControl>


                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="delivery-types">Szállítási mód</InputLabel>
                    <CustomSelect
                        labelId="delivery-types"
                        label="Szállítási mód"
                        name="delivery_type"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value=''>-</MenuItem>
                        {Object.entries(deliveryTypes).map(([key, value]) => <MenuItem key={key}
                                                                                       value={value}>{value}</MenuItem>)}
                    </CustomSelect>
                </FormControl>


                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="return-types">Visszaszállítás módja</InputLabel>
                    <CustomSelect
                        labelId="return-types"
                        label="Vissza szállítási mód"
                        name="return_type"
                        data={formState}
                        setData={setFormState}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    >
                        <MenuItem value=''>-</MenuItem>
                        {Object.entries(deliveryTypes).map(([key, value]) => <MenuItem key={key}
                                                                                       value={value}>{value}</MenuItem>)}
                    </CustomSelect>
                </FormControl>
            </Stack>


            <CustomTextField
                sx={{width: '100%'}}
                name="DHL_delivery_number"
                label="Oda fuvarlevélszám"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <CustomTextField
                sx={{width: '100%'}}
                name="DHL_order"
                label="Oda felvételazonosító"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <CustomTextField
                sx={{width: '100%'}}
                name="DHL_return_number"
                label="Vissza fuvarlevélszám"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <CustomTextField
                sx={{width: '100%'}}
                name="DHL_order_back"
                label="Vissza felvételazonosító"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

            <Typography variant="h6" gutterBottom>Visszavétel Check</Typography>

            <CustomTextField
                sx={{width: '100%'}}
                name="returned_rx"
                label="Visszahozott vevők száma"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <CustomTextField
                sx={{width: '100%'}}
                name="returned_tx"
                label="Visszahozott adók száma"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <Typography variant="h6" gutterBottom>Pénzügyi adatok</Typography>


            <CustomTextField
                sx={{width: '100%'}}
                name="extra_delivery_price"
                label="Extra kiszállítási ár"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <CustomTextField
                sx={{width: '100%'}}
                name="agreed_price"
                label="Megegyezett ár"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="invoice-type">Számla típusa</InputLabel>
                <CustomSelect
                    labelId="invoice-type"
                    label="Számla típusa"
                    id="invoice"
                    name="invoice"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    <MenuItem value=''>-</MenuItem>
                    {Object.entries(invoiceTypes).map(([key, value]) => <MenuItem key={key}
                                                                                  value={value}>{value}</MenuItem>)}
                </CustomSelect>
            </FormControl>


            <CustomTextField
                sx={{width: '100%'}}
                name="invoice_number"
                label="Számlasorszám"
                data={formState}
                setData={setFormState}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />


            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="currency-types">Valuta</InputLabel>
                <CustomSelect
                    labelId="currency-types"
                    label="Valuta"
                    id="currency"
                    name="currency"
                    data={formState}
                    setData={setFormState}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    <MenuItem value=''>-</MenuItem>
                    {Object.entries(currencies).map(([key, value]) => <MenuItem key={key}
                                                                                value={value}>{value}</MenuItem>)}
                </CustomSelect>

            </FormControl>

        </Stack>


        <DinoDialog
            title="Új csoportkísérő"
            open={isTourGuideDialogOpen}
            handleClose={() => toggleTourGuideDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleTourGuideDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    const data = newTourGuideForm.current.getData()
                    if (Object.entries(data).length > 0) {
                        toggleTourGuideDialog(false)
                        addTourGuide(data)
                    }
                }}>Hozzáadás</Button>
            </Stack>}>
            <NewTourGuideData reference={newTourGuideForm}/>
        </DinoDialog>


        <DinoDialog
            title="Új megrendelő"
            open={isTourOfficeDialogOpen}
            handleClose={() => toggleTourOfficeDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleTourOfficeDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    const data = newTourOfficeForm.current.getData()
                    if (Object.entries(data).length > 0) {
                        toggleTourOfficeDialog(false)
                        addTourOffice(data)
                    }
                }}>Hozzáadás</Button>
            </Stack>}>
            <NewTourOfficeData reference={newTourOfficeForm}/>
        </DinoDialog>


        <Loading isLoading={Object.values(loading).some(value => value === true)}/>
    </ErrorHandlingForm>
};

export default OrderForm;
