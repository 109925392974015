import PersistentDrawerLeft from "../../components/NewDrawer";
import ControlledEditor from "../../components/Editor";
import {useEffect, useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import {convertToRaw, EditorState, ContentState} from "draft-js";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {useSnackbar} from "notistack";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


const EmailTemplates = () => {
    const {getData: fetchEmailTemplates, postData: postEmailTemplates} = useCallDataApi('email-templates')
    const [variables, setVariables] = useState([])
    const [loading, setLoading] = useState(true)
    const [emailTexts, setEmailTexts] = useState({
        order_confirmation_email: EditorState.createEmpty(),
        order_cancellation_email: EditorState.createEmpty(),
        new_order_email: EditorState.createEmpty()
    })
    const setConfirmation = (newVal) => setEmailTexts({...emailTexts, order_confirmation_email: newVal})
    const setCancellation = (newVal) => setEmailTexts({...emailTexts, order_cancellation_email: newVal})
    const setNew = (newVal) => setEmailTexts({...emailTexts, new_order_email: newVal})
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        fetchEmailTemplates('get_variables').then(r => setVariables(r))
        fetchEmailTemplates('get_templates')
            .then(r => setEmailTexts({
                order_confirmation_email: editorStateFromHtml(r?.order_confirmation_email),
                order_cancellation_email: editorStateFromHtml(r?.order_cancellation_email),
                new_order_email: editorStateFromHtml(r?.new_order_email)
            }))
            .finally(() => setLoading(false))
    }, [])

    const htmlFromEditorState = (editorState) => {
        const rawContentState = convertToRaw(editorState?.getCurrentContent())
        return draftToHtml(
            rawContentState,
            // hashtagConfig,
            // directional,
            // customEntityTransform
        )
    }

    const editorStateFromHtml = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);
    }

    const editEmailTemplates = () => {
        setLoading(true)
        postEmailTemplates('edit_templates', {
            order_confirmation_email: htmlFromEditorState(emailTexts?.order_confirmation_email),
            order_cancellation_email: htmlFromEditorState(emailTexts?.order_cancellation_email),
            new_order_email: htmlFromEditorState(emailTexts?.new_order_email)
        })
            .then(r => {
                setEmailTexts({
                    order_confirmation_email: editorStateFromHtml(r?.order_confirmation_email),
                    order_cancellation_email: editorStateFromHtml(r?.order_cancellation_email),
                    new_order_email: editorStateFromHtml(r?.new_order_email)
                })
                enqueueSnackbar('Sablonok elmentve!', {variant: 'success'})
            })
            .finally(() => setLoading(false))
    }

    return <PersistentDrawerLeft title='Email sablonok'>
        <Typography variant='h4' gutterBottom>E-mail változók:</Typography>
        <Stack spacing={2} direction='row' marginBottom={3} sx={{ flexWrap: 'wrap' }}>
            {variables.map(v => <Button variant='outlined' endIcon={<ContentCopyRoundedIcon/>} key={v.id} onClick={() => navigator.clipboard.writeText(v.value).then(() => enqueueSnackbar('Másolva!', {variant: 'success'}))}>{v.name}</Button>)}
        </Stack>
        <ControlledEditor title='Rendelés megerősítése email' setEditorState={setConfirmation} editorState={emailTexts?.order_confirmation_email}/>
        <ControlledEditor title='Rendelés törlése email' setEditorState={setCancellation} editorState={emailTexts?.order_cancellation_email}/>
        <ControlledEditor title='Új rendelés email' setEditorState={setNew} editorState={emailTexts?.new_order_email}/>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 2}}>
            <Button onClick={() => editEmailTemplates()} variant='contained' color='success' endIcon={<SaveRoundedIcon/>}>Mentés</Button>
        </Box>
        <Loading isLoading={loading}/>
    </PersistentDrawerLeft>
}

export default EmailTemplates