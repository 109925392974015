import {Chip} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import PrintIcon from '@mui/icons-material/Print';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';


const Pending = () => <Chip icon={<PauseRoundedIcon />} label="Pending" variant="outlined" color='warning'/>
const Confirmed = () => <Chip icon={<DoneIcon />} label="Confirmed" variant="outlined" color='success'/>
const DNPrinted = () => <Chip icon={<PrintIcon />} label="DN Printed" variant="outlined" color='warning'/>
const Delivered = () => <Chip icon={<DeliveryDiningRoundedIcon />} label="Delivered" variant="outlined" color='warning'/>
const CourierOrdered = () => <Chip icon={<PersonRoundedIcon />} label="Courier ordered" variant="outlined" color='warning'/>
const Returning = () => <Chip icon={<ArrowBackIosNewRoundedIcon />} label="Returning" variant="outlined" color='warning'/>
const Returned = () => <Chip icon={<DoneAllRoundedIcon />} label="Returned" variant="outlined" color='success'/>
const Cancelled = () => <Chip icon={<CloseRoundedIcon />} label="Cancelled" variant="outlined" color='error'/>
const OnTheWayOut = () => <Chip icon={<KeyboardArrowRightRoundedIcon />} label="On the way out" variant="outlined" color='warning'/>

export {
    Pending,
    Confirmed,
    DNPrinted,
    Delivered,
    CourierOrdered,
    Returning,
    Returned,
    Cancelled,
    OnTheWayOut,
}