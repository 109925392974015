import { useState, useEffect } from 'react';

const useCurrentLocation = () => {
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser');
            setLoading(false);
            return;
        }

        const successHandler = (position) => {
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
            setLoading(false);
        };

        const errorHandler = (error) => {
            setError(error.message);
            setLoading(false);
        };

        navigator.geolocation.getCurrentPosition(successHandler, errorHandler);

        // Optionally, you can use watchPosition if you want to track location updates:
        // const watchId = navigator.geolocation.watchPosition(successHandler, errorHandler, options);
        // return () => navigator.geolocation.clearWatch(watchId);
    }, []);

    return { location, loading, error };
};

export default useCurrentLocation;
