import {InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import locale from "date-fns/locale/hu";
import FormControl from "@mui/material/FormControl";
import {formatHuman} from "../../utils/utils";
import useCallDataApi from "../../hooks/data";
import CustomTextField from "../../components/formValidation/CustomTextField";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingFrom";
import {validateNonNegativeNumber, validateNumber, validateRequired} from "../../components/formValidation/validators";

const CouponCodeData = ({reference, obj}) => {
    const {getData} = useCallDataApi('coupon-codes')
    const [users, setUsers] = useState([])

    useEffect(() => {
        getData('get_users').then(u => setUsers(u))
    }, [])

    const [formData, setFormData] = useState({
        name: obj?.name || '',
        percent: obj?.percent || null,
        minus: obj?.minus || null,
        valid_thru: obj?.valid_thru || formatHuman(new Date()),
        allowed_users: obj?.allowed_users || [],
    })

    const [errors, setErrors] = useState({
        name: false,
        percent: false,
        minus: false,
        valid_thru: false,
        allowed_users: false,
    })

    const validators = {
        name: [validateRequired],
        percent: formData?.minus ? [] : [validateNumber, validateNonNegativeNumber],
        minus: formData?.percent ? [] : [validateNumber, validateNonNegativeNumber],
        valid_thru: [],
        allowed_users: [],
    };

    const validateCantBeBoth = formData => {
        return {
            valid: !Boolean(formData?.minus && formData?.percent),
            error: 'A kedvezmény nem lehet egyszerre százalék és minusz is!'
        }
    }

    const validateCantBeNone = formData => {
        return {
            valid: Boolean(formData?.minus || formData?.percent),
            error: 'Legalább az egyiket meg kell adni: százalék, kedvezmény!'
        }
    }

    return <ErrorHandlingForm customValidators={[validateCantBeNone, validateCantBeBoth]} reference={reference} errors={errors} setErrors={setErrors} data={formData} setData={setFormData} validators={validators} style={{marginTop: '15px'}}>
        <Stack spacing={2}>
            <CustomTextField
                label="Név"
                name="name"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <CustomTextField
                label="Százalék"
                name="percent"
                nullable
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <CustomTextField
                label="Kedvezmény összege"
                name="minus"
                nullable
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <DatePicker
                    label="Érvényesség"
                    value={formData?.valid_thru}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    onChange={(newValue) => setFormData({...formData, valid_thru: formatHuman(newValue)})}
                    InputAdornmentProps={{ position: 'start' }}
                    renderInput={(params) => <TextField id="valid_thru" InputAdornmentProps={{ position: 'start' }} name="valid_thru" {...params} />}
                />
            </LocalizationProvider>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="allowed-users-label">Jogosult felahsználók</InputLabel>
                <Select
                    labelId="allowed-users-label"
                    label="Jogosult felahsználók"
                    id="allowed_users"
                    name="allowed_users"
                    multiple
                    value={formData?.allowed_users}
                    onChange={e => setFormData({...formData, allowed_users: e.target.value})}
                >
                    {users?.map(u => <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>)}
                </Select>
            </FormControl>

        </Stack>
    </ErrorHandlingForm>
}

export default CouponCodeData