import useCallDataApi from "../../hooks/data";
import {useEffect, useImperativeHandle, useState} from "react";
import {Stack} from "@mui/material";
import CouponCodeDetail from "../couponCodes/CouponCodeDetail";
import Loading from "../../components/Loading";

const SelectDiscount = ({reference, order_id}) => {
    const {getData} = useCallDataApi('orders')
    const [discounts, setDiscounts] = useState([])
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        setLoading(true)
        getData(`${order_id}/get_available_discounts`).then(d => setDiscounts(d)).finally(() => setLoading(false))
        getData(order_id).then(o => setSelected(o?.discount || -1))
    }, [])

    const getSelected = () => selected

    useImperativeHandle(reference, () => ({
        getSelected
    }))

    return <Stack spacing={2}>
        {discounts.map(code => <CouponCodeDetail handle={() => {
            code?.id === selected ? setSelected(null) : setSelected(code?.id)
        }} selected={selected === code?.id} key={code?.id} coupon={code}/>)}

        <Loading isLoading={loading}/>
    </Stack>
}

export default SelectDiscount