import { useCallback, useState, useContext } from "react";
import { useAuthHeader } from "./hooks.js";
import AuthContext from "../context/AuthContext";
import ConfigContext from "../context/ConfigContext";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";

function useCallDataApi(model) {
	const { headers } = useAuthHeader();
	const { logoutUser } = useContext(AuthContext);
	const { baseUrl } = useContext(ConfigContext);
	const history = useHistory()

	const url = baseUrl + `core`;

	const [isLoading, setLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();

	const fetchData = useCallback(
		async ({ id, method, body }) => {
			const response = await fetch(`${url}/${model}/${id ? id+'/' : ''}`, {
				method: method,
				headers,
				body,
			});
			if (response.status === 401) {
				logoutUser();
			}
			if (response.status === 403) {
				history.push("/");
			}
			if (response.status >= 400) {
				console.log(response)
				enqueueSnackbar('Something went wrong', {variant: 'error'})
			}
			return response;
		},
		[headers, logoutUser, url, model]
	);

	const createData = useCallback(
		async (data) => {
			setLoading(true);
			const body = JSON.stringify(data);
			const response = await fetchData({ method: "POST", body });
			const returnData = await response?.json();
			if (response.status === 201) {
				setLoading(false);
				return returnData;
			}
			setLoading(false);
		},
		[fetchData]
	);

	const postData = useCallback(
		async (slug, data) => {
			const body = JSON.stringify(data);
			const response = await fetchData({ id: slug, method: "POST", body });
			return await response?.json();
		},
		[fetchData]
	);

	const getData = useCallback(
		async (id = null) => {
			setLoading(true);
			const response = await fetchData({ id, method: "GET" });
			const returnData = await response?.json();
			if (response.status === 200) {
				setLoading(false);
				return returnData;
			}
			setLoading(false);
		},
		[fetchData]
	);

	const updateData = useCallback(
		async (id, data) => {
			setLoading(true);
			const body = JSON.stringify(data);
			const response = await fetchData({ id, method: "PUT", body });
			const returnData = await response?.json();
			if (response.status === 200) {
				setLoading(false);
				return returnData;
			}
			setLoading(false);
		},
		[fetchData]
	);

	const patchData = useCallback(
		async (id, data) => {
			setLoading(true);
			const body = JSON.stringify(data);
			const response = await fetchData({ id, method: "PATCH", body });
			const returnData = await response?.json();
			if (response.status === 200) {
				setLoading(false);
				return returnData;
			}
			setLoading(false);
		},
		[fetchData]
	);

	const deleteData = useCallback(
		async (id) => {
			setLoading(true);
			const response = await fetchData({ id, method: "DELETE" });
			if (response.status === 204) {
				setLoading(false);
				return response;
			}
			setLoading(false);
		},
		[fetchData]
	);

	const getFile = useCallback(
		async (id, extension, name='data') => {
			const response = await fetch(`${url}/${model}/${id ? id+'/' : ''}`, {
				method: 'GET',
				headers,
			});
			const blob = await response?.blob();
			if (response.status === 401) {
				logoutUser();
			}
			if (response.status === 403) {
				history.push("/");
			}
			if (response.status === 200) {
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement('a');
				a.href = url;
				a.download = `${name}.${extension}`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			}
			return response;
		},
		[headers, logoutUser]
	);

	const postFile = useCallback(
		async (id, data, extension, name='data') => {
			const body = JSON.stringify(data)
			const response = await fetch(`${url}/${model}/${id ? id+'/' : ''}`, {
				method: 'POST',
				headers,
				body
			});
			const blob = await response?.blob();
			if (response.status === 401) {
				logoutUser();
			}
			if (response.status === 403) {
				history.push("/");
			}
			if (response.status === 200) {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `${name}.${extension}`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			}
			return response;
		},
		[headers, logoutUser]
	);

	return {
		getData,
		createData,
		updateData,
		patchData,
		deleteData,
		postData,
		getFile,
		postFile
	};
}

export default useCallDataApi;
