import {InputLabel, MenuItem, Stack} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, {useState} from "react";
import {validateRequired, validateNumber, validateNonNegativeNumber} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingFrom";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import CustomSelect from "../../../components/formValidation/CustomSelect";
import {unitPriceTypes, units, vat} from "../../../utils/enums";

const InvoiceItemData = ({reference, obj}) => {

    const [formData, setFormData] = useState({
        name: obj?.name || '',
        unit_price: obj?.unit_price || 0,
        unit_price_type: obj?.unit_price_type || unitPriceTypes.NETTO,
        quantity: obj?.quantity || 1,
        unit: obj?.unit || units.PIECE,
        vat: obj?.vat || vat.NORMAL,
        comment: obj?.comment || ''
    })

    const [errors, setErrors] = useState({
        name: false,
        unit_price: false,
        unit_price_type: false,
        quantity: false,
        unit: false,
        vat: false,
        comment: false,
    })

    const validators = {
        name: [validateRequired],
        unit_price: [validateRequired, validateNumber, validateNonNegativeNumber],
        unit_price_type: [validateRequired],
        quantity: [validateRequired, validateNumber, validateNonNegativeNumber],
        unit: [validateRequired],
        vat: [validateRequired],
        comment: []
    };


    return <ErrorHandlingForm reference={reference} errors={errors} setErrors={setErrors} data={formData}
                              setData={setFormData} validators={validators} style={{marginTop: '15px'}}>
        <Stack spacing={2}>

            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="name"
                label='Tétel megnevezése'/>

            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="unit_price"
                label='Egységár'/>

            <FormControl>
                <InputLabel id="unit_price_type-label">Egységár típus</InputLabel>
                <CustomSelect
                    labelId="unit_price_type-label"
                    label="Egységár típusm"
                    id="unit_price_type"
                    name="unit_price_type"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    {Object.values(unitPriceTypes).map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                </CustomSelect>
            </FormControl>

            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators} name="quantity"
                label='Mennyiség'/>

            <FormControl>
                <InputLabel id="unit-label">Mértékegység</InputLabel>
                <CustomSelect
                    labelId="unit-label"
                    label="Mértékegység"
                    id="unit"
                    name="unit"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                >
                    {Object.values(units).map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                </CustomSelect>
            </FormControl>

            <FormControl>
                <InputLabel id="vat-label">Áfa tartalom</InputLabel>
                <CustomSelect
                    labelId="vat-label"
                    label="Áfa tartalom"
                    id="vat"
                    name="vat"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                >
                    {Object.values(vat).map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                </CustomSelect>
            </FormControl>

            <CustomTextField
                multiline
                rows={3}
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="comment"
                label='Leírás'/>
        </Stack>
    </ErrorHandlingForm>
}

export default InvoiceItemData