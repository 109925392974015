import {InputLabel, MenuItem, Select} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";


export default function DeliveryPeriodData({reference, order}) {
    const [deliveryPeriod, setDeliveryPeriod] = useState(order?.delivery_period || "");
    const [returnPeriod, setReturnPeriod] = useState(order?.return_period || "");

    const handleDeliveryChange = (event) => {
        setDeliveryPeriod(event.target.value)
    }

    const handleReturnChange = (event) => {
        setReturnPeriod(event.target.value)
    }

    return <form ref={reference}>
        <Stack>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="delivery-select">Kiszállítási idő</InputLabel>
                <Select
                    labelId="delivery-select"
                    label="Kiszállítási idő"
                    id="delivery_period"
                    name="delivery_period"
                    value={deliveryPeriod}
                    onChange={handleDeliveryChange}
                >
                    <MenuItem value={"-"}>None</MenuItem>
                    <MenuItem key="EN" value="EN">EN</MenuItem>
                    <MenuItem key="DU" value="DU">DU</MenuItem>
                    <MenuItem key="DE" value="DE">DE</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="return-select">Visszaszállítási idő</InputLabel>
                <Select
                    labelId="return-select"
                    label="Visszaszállítási idő"
                    id="return_period"
                    name="return_period"
                    value={returnPeriod}
                    onChange={handleReturnChange}
                >
                    <MenuItem value={"-"}>None</MenuItem>
                    <MenuItem key="EN" value="EN">EN</MenuItem>
                    <MenuItem key="DU" value="DU">DU</MenuItem>
                    <MenuItem key="DE" value="DE">DE</MenuItem>
                </Select>
            </FormControl>
        </Stack>
    </form>;
}