import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import {getDetails, getGeocode, getLatLng} from "use-places-autocomplete";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMapsAutocomplete({setAddress, ...rest}) {
    const [value, setValue] = React.useState(rest?.value || null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    // if (typeof window !== 'undefined' && !loaded.current) {
    //     if (!document.querySelector('#google-maps')) {
    //         loadScript(
    //             `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
    //             document.querySelector('head'),
    //             'google-maps',
    //         );
    //     }
    //
    //     loaded.current = true;
    // }

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        let restrictions = {}
        if (rest?.onlyHungary) {
            restrictions = {componentRestrictions: { country: 'hu' }}
        }

        fetch({ input: inputValue,  ...restrictions}, (results) => {
            console.log(results)
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const getFormattedAddress = addressDetails => {
        const streetNumber = addressDetails?.find(d => d.types?.includes('street_number'))?.short_name || ''
        const streetName = addressDetails?.find(d => d.types?.includes('route'))?.short_name || ''
        const city = addressDetails?.find(d => d.types?.includes('locality'))?.short_name || ''
        const postalCode = addressDetails?.find(d => d.types?.includes('postal_code'))?.short_name || ''
        const country = addressDetails?.find(d => d.types?.includes('country'))?.short_name || ''
        return {
            street_number: streetNumber,
            street: streetName,
            postal_code: postalCode,
            city: city,
            country: country
        }
    }

    const onSelect = async (address) => {
        if (address) {
            const results = await getGeocode({address})
            const {lat, lng} = await getLatLng(results[0])
            const {address_components} = await getDetails({placeId: results[0]?.place_id, fields: ["address_components"]})
            setAddress({address: getFormattedAddress(address_components), coordinates: {lat: lat, lng: lng}, address_com: address})
        }
    }

    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            sx={{minWidth: '250px'}}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            {...rest}

            noOptionsText="No locations"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                onSelect(newValue?.description)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} fullWidth label={rest?.label} />
            )}
            renderOption={(props, option) => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}