import React, {useContext, useEffect, useRef, useState} from "react";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import useCallDataApi from "../../hooks/data";
import {Button, Stack} from "@mui/material";
import {useHistory} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {useSnackbar} from "notistack";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TrackingDialog from "../../components/TrackingDialog";
import CloseIcon from "@mui/icons-material/Close";
import {cleanData} from "../../utils/FormUtils";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import DinoDialog from "../../components/Dialog";
import DataGridWrapper from "../../components/DataGridWrapper";
import NewTourGuideData from "./NewTourGuideData";
import DataGridWithSearch from "../../components/DataGridWithSearch";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

const TourGuides = () => {
    const [tourGuides, setTourGuides] = useState([]);
    const [isDeleteTourGuideDialogOpen, toggleDeleteTourGuideDialog] = useState(false)
    const [isEditTourGuideDialogOpen, toggleEditTourGuideDialog] = useState(false)
    const [isTourGuideDialogOpen, toggleTourGuideDialog] = useState(false)
    const [isTrackingDialogOpen, toggleTrackingDialog] = useState(false)
    const [isValidateDialogOpen, toggleValidateDialog] = useState(false);
    const [current, setCurrent] = useState(1);
    const [tracking, setTracking] = useState([])
    const [loading, setLoading] = useState(true)

    const {getData: fetchTourGuides, createData, updateData, deleteData} = useCallDataApi("tour-guides");

    const history = useHistory();
    const {permissions} = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar()

    const editTourGuideForm = useRef(null);
    const newTourGuideForm = useRef(null);

    if (!permissions.includes('view_tourguide')) history.push('/')

    const newTourGuide = (tourGuideData) => {
        createData(tourGuideData).then(newTourGuide => {
            setTourGuides(tourGuide => [...tourGuide, newTourGuide])
            enqueueSnackbar('Csoportkísérő mentve!', {variant: 'success'})
        })
    }

    const editTourGuide = (tourGuideData) => {
        updateData(current, tourGuideData).then(editedTourGuide => {
            const editedTourGuides = tourGuides.map(tourGuide => {
                if (current === tourGuide.id) return {...tourGuide, ...editedTourGuide}
                return tourGuide;
            });
            setTourGuides(editedTourGuides)
            enqueueSnackbar('Tour Guide Changed!', {variant: 'success'})
        })
    }

    const deleteTourGuide = () => {
        deleteData(current).then(() => {
            setTourGuides(tourGuides.filter(tourGuide => (tourGuide.id !== current)))
            enqueueSnackbar('Tour Guide Deleted!', {variant: 'success'})
        })
    }

    const getTracking = (id) => {
        fetchTourGuides(`${id}/track`).then(t => setTracking(t))
    }

    const invalidate = (id) => {
        fetchTourGuides(`${id}/invalidate`).then(editedTourGuide => {
            const editedTourGuideList = tourGuides.filter(t => (current !== t.id))
            setTourGuides(editedTourGuideList)
            enqueueSnackbar("Érvénytelenítve!", {variant: 'success'})
        })
    }

    useEffect(() => {
        const getData = async () => {
            const [g] = await Promise.all([
                fetchTourGuides(),
            ])
            if (g) setTourGuides(g)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
    }, [])

    const getTourGuide = (id) => tourGuides.find(tourGuide => (tourGuide.id === id))

    const columns = [
        {
            field: "manage",
            headerName: "Kezelés",
            sortable: false,
            type: "actions",
            getActions: (params) => {
                let menu = []
                if (permissions.includes('delete_tourguide')) menu.push(<GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleDeleteTourGuideDialog(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />)

                if (permissions.includes('change_tourguide')) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szerkesztés"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditTourGuideDialog(true)
                    }}
                    showInMenu={true}/>)

                if (permissions.includes('can_track_tourguide')) menu.push(<GridActionsCellItem
                    icon={<AccountTreeIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        getTracking(params.row["id"])
                        toggleTrackingDialog(true)
                    }}
                    label="Nyomonkövetés"
                    showInMenu={true}
                />)
                if (permissions.includes('can_validate_tourguide')) menu.push(<GridActionsCellItem
                    icon={<CloseIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleValidateDialog(true)
                    }}
                    label="Érvénytelenítés"
                    showInMenu={true}
                />)
                return menu
            },
        },
        {field: "first_name", headerName: "Keresztnév", width: 130},
        {field: "last_name", headerName: "Vezetéknév", width: 130},
        {field: "phone", headerName: "Phone", width: 130},
        {field: "email", headerName: "Email", width: 200},
        {field: "tourguide_type", headerName: "Típus", width: 200},
    ];

    const newDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleTourGuideDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            const data = newTourGuideForm.current.getData()
            if (Object.entries(data).length > 0) {
                toggleTourGuideDialog(false)
                newTourGuide(data)
            }
        }}>Hozzáadás</Button>
    </Stack>

    const editDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleEditTourGuideDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            const data = editTourGuideForm.current.getData()
            if (Object.entries(data).length > 0) {
                toggleEditTourGuideDialog(false)
                editTourGuide(data)
            }
        }}>Szerkesztés</Button>
    </Stack>

    const deleteDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleDeleteTourGuideDialog(false)}>Mégsem</Button>
        <Button size='large' variant='contained' color='error' onClick={() => {
            deleteTourGuide(current)
            toggleDeleteTourGuideDialog(false)
        }}>törlés</Button>
    </Stack>

    const invalidateDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleValidateDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='error' onClick={() => {
            invalidate(current)
            toggleValidateDialog(false)
        }}>érvénytelenítés</Button>
    </Stack>

    return (
        <div style={{height: "100vh"}}>
            <PersistentDrawerLeft title="Csoportkísérők" buttons={[<Button color='success' disabled={!permissions?.includes('add_tourguide')} variant='outlined' endIcon={<AddCircleOutlineRoundedIcon/>} onClick={() => toggleTourGuideDialog(true)}>Új csoportkísérő</Button>]}>
                <DataGridWithSearch rows={tourGuides} columns={columns} name='tour-guides'/>
                <Loading isLoading={loading}/>
            </PersistentDrawerLeft>

            {/*{permissions.includes('add_tourguide') && <BasicSpeedDial onClick={() => toggleTourGuideDialog(true)}/>}*/}

            <DinoDialog
                title={`Biztos benne, hogy törölni szeretné ezt a csoportkísárőt: ${getTourGuide(current)?.first_name}?`}
                subtitle='Az összes hozzátartozó adat elvész.'
                open={isDeleteTourGuideDialogOpen}
                handleClose={() => toggleDeleteTourGuideDialog(false)}
                actions={deleteDialogActions} />

            <DinoDialog
                title='Csoportkísérő szerekesztése'
                subtitle='Csoportkísérő szerekesztése'
                open={isEditTourGuideDialogOpen}
                handleClose={() => toggleEditTourGuideDialog(false)}
                actions={editDialogActions}>
                <NewTourGuideData reference={editTourGuideForm} obj={getTourGuide(current)}/>
            </DinoDialog>

            <DinoDialog
                title='Új csoportkísérő'
                subtitle='Csoportkísérő hozzáadása'
                open={isTourGuideDialogOpen}
                handleClose={() => toggleTourGuideDialog(false)}
                actions={newDialogActions}>
                <NewTourGuideData reference={newTourGuideForm}/>
            </DinoDialog>

            <DinoDialog
                title={`Biztosan érvényteleníti: ${getTourGuide(current)?.first_name}?`}
                subtitle='Csak admin felhasználó tudja ezt visszavonni.'
                open={isValidateDialogOpen}
                handleClose={() => toggleValidateDialog(false)}
                actions={invalidateDialogActions} />

            <TrackingDialog
                model={getTourGuide(current)?.first_name}
                open={isTrackingDialogOpen}
                onClose={() => (toggleTrackingDialog(false))}
                trackingInfo={tracking}/>

        </div>
    );
};

export default TourGuides;
