import {TextField} from "@mui/material";


const CustomTextField = ({errors, setErrors, data, setData, name, validators, nullable=false, ...rest}) => {

    const handleChange = (event) => {
        const {name, value} = event.target;
        if (nullable && value === "") {
            setData({...data, [name]: null})
        } else {
            setData({...data, [name]: value})
        }
    };

    const handleBlur = (event) => {
        const {name, value} = event.target;
        for (let validator of validators[name]) {
            const {valid, error} = validator(value)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: ''})
        }
    };

    return <TextField
        name={name}
        value={data[name]}
        onChange={e => {
            handleBlur(e)
            handleChange(e)
        }}
        onBlur={handleBlur}
        error={Boolean(errors[name])}
        helperText={errors[name] && errors[name]}
        {...rest}
    />
}

export default CustomTextField
