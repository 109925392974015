import React, {useContext, useEffect} from "react";
import {Box, Grid, Typography, useTheme} from "@mui/material";

import PersistentDrawerLeft from "../components/NewDrawer";
import {Link} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MopedRoundedIcon from "@mui/icons-material/MopedRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AuthContext from "../context/AuthContext";
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import useCurrentLocation from "../hooks/location";
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';

const HomePage = () => {
    const {permissions} = useContext(AuthContext)
    const {location} = useCurrentLocation()
    const theme = useTheme()
    const iconSize = 100
    const menuItems = [
        {
            permissions: ["view_order"],
            link: "/orders",
            name: "Megrendelések",
            icon: <HeadphonesIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_touroffice"],
            link: "/tour-offices",
            name: "Megrendelők",
            icon: <EmojiTransportationIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_tourguide"],
            link: "/tour-guides",
            name: "Csoportkísérők",
            icon: <PeopleAltIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_daily"],
            link: "/daily",
            name: "Kiszállítások",
            icon: <ForwardToInboxIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_daily"],
            link: "/courier",
            name: "Futárok",
            icon: <MopedRoundedIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_report"],
            link: "/reports",
            name: "Riportok",
            icon: <AssessmentIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_emailtemplate"],
            link: "/email-templates",
            name: "Email sablonok",
            icon: <AlternateEmailRoundedIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_order"],
            link: "/order-return",
            name: "Visszaérkeztetés",
            icon: <ArrowBackIosRoundedIcon style={{ fontSize: iconSize }}/>
        },
        {
            permissions: ["view_couponcode"],
            link: "/coupon-codes",
            name: "Kuponkódok",
            icon: <LocalOfferRoundedIcon style={{ fontSize: iconSize }}/>
        },
        // {
        //     permissions: ["view_order"],
        //     link: "/scanner",
        //     name: "Qr kód",
        //     icon: <QrCodeRoundedIcon style={{ fontSize: iconSize }}/>
        // },
    ]

    if (location) console.log(location)

    return (
        <div>
            <PersistentDrawerLeft title="Kezelőpult">
                <Grid container spacing={2}>
                    {menuItems.filter(item => item.permissions.every(i => permissions.includes(i))).map(item => <Grid key={item.name} item xs={12} lg={2}>
                        <Link to={item?.link} style={{textDecoration: 'none'}}>
                            <Box sx={{background: theme.palette.primary.main, color: theme.palette.background.default, borderRadius: '5px', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                {item?.icon}
                                <Typography variant='h5'>{item?.name}</Typography>
                            </Box>
                        </Link>
                    </Grid>)}
                </Grid>
            </PersistentDrawerLeft>
        </div>
    );
};

export default HomePage;
