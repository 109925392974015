import {createContext} from "react";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {

    const contextData = {
        /* baseUrl: 'http://127.0.0.1:8000/', */
        baseUrl: 'https://admin.tourguidesystem.hu/',
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}