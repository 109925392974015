import {Card, CardContent, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import {determineAddress} from "../../utils/utils";

const OrderDetail = ({orderId}) => {
    const {getData: fetchOrder} = useCallDataApi('orders')
    const {postData: postTourOffice} = useCallDataApi('tour-offices')
    const {postData: postTourGuide} = useCallDataApi('tour-guides')
    const [order, setOrder] = useState({})
    const [tourOffice, setTourOffice] = useState({})
    const [tourGuide, setTourGuide] = useState({})

    useEffect(() => {
        fetchOrder(orderId).then(o => setOrder(o))
        postTourOffice('tour_office_for_order', {order_id: orderId}).then(t => setTourOffice(t))
        postTourGuide('tour_guide_for_order', {order_id: orderId}).then(t => setTourGuide(t))
    }, [])

    return <>
        <Card sx={{minWidth: 350}} style={{marginBottom: '3px'}}>
            <CardContent style={{width: '100%'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Általános infó
                </Typography>
                <Typography gutterBottom variant="p">
                    Bérlési idő: {order?.days} nap
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Számított ár: {order?.price} {order?.currency}
                    <br/>
                    Megadott ár: {order?.agreed_price} {order?.currency}
                </Typography>
                <table className="detail-table">
                    <tbody>
                    <tr className="table-row">
                        <td>Megjegyzés:</td>
                        <td>{order?.comment}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Belsős megjegyzés:</td>
                        <td>{order?.internal_comment}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Rendelés szám:</td>
                        <td>{order?.order_number}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Lezárva:</td>
                        <td>{order?.closed ? 'Closed' : 'Open'}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Állapot:</td>
                        <td>{order?.order_status}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Program:</td>
                        <td>{order?.tour_type}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Megrendelő:</td>
                        <td>{tourOffice?.name}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Megnevezés:</td>
                        <td>{order?.tour_code}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Csoportkísérő:</td>
                        <td>{tourGuide?.show_name}</td>
                    </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
        <Card sx={{minWidth: 350}} style={{marginBottom: '3px'}}>
            <CardContent style={{width: '100%'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Szállítási infó
                </Typography>
                <table className="detail-table">
                    <tbody>
                    <tr className="table-row">
                        <td>Szállítás típusa:</td>
                        <td>{order?.delivery_type}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Kiszállítás dátuma:</td>
                        <td>{order?.delivery_date}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Kiszállítás ideje:</td>
                        <td>{order?.delivery_time}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Előtte:</td>
                        <td>{order?.days_before}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Kiszállítási cím:</td>
                        <td>{determineAddress(order?.delivery_address)}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Delivery format:</td>
                        <td>{order?.delivery_format}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Oda fuvarlevélszám:</td>
                        <td>{order?.DHL_delivery_number}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Oda felvételazonosító:</td>
                        <td>{order?.DHL_order}</td>
                    </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
        <Card sx={{minWidth: 350}} style={{marginBottom: '3px'}}>
            <CardContent style={{width: '100%'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Eszközök
                </Typography>
                <table className="detail-table">
                    <tbody>
                    <tr className="table-row">
                        <td>Szín:</td>
                        <td>{order?.color}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Rendelt RX:</td>
                        <td>{order?.order_rx}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Rendelt TX:</td>
                        <td>{order?.order_tx}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Elküldött RX:</td>
                        <td>{order?.sent_rx}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Elküldött TX:</td>
                        <td>{order?.sent_tx}</td>
                    </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
        <Card sx={{minWidth: 350}} style={{marginBottom: '3px'}}>
            <CardContent style={{width: '100%'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Visszaszállítás adatai
                </Typography>
                <table className="detail-table">
                    <tbody>
                    <tr className="table-row">
                        <td>Return type:</td>
                        <td>{order?.return_type}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Return date:</td>
                        <td>{order?.return_date}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Return time:</td>
                        <td>{order?.return_time}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Utána:</td>
                        <td>{order?.days_after}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Visszaérkezett RX:</td>
                        <td>{order?.returned_rx}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Return address:</td>
                        <td>{determineAddress(order?.return_address)}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Vissza fuvarlevélszám:</td>
                        <td>{order?.DHL_return_number}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Vissza felvételazonosító:</td>
                        <td>{order?.DHL_order_back}</td>
                    </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
        <Card sx={{minWidth: 350}} style={{marginBottom: '3px'}}>
            <CardContent style={{width: '100%'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Pénzügyi adatok
                </Typography>
                <table className="detail-table">
                    <tbody>
                    <tr className="table-row">
                        <td>Pénznem:</td>
                        <td>{order?.currency}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Számla típusa:</td>
                        <td>{order?.invoice}</td>
                    </tr>
                    <tr className="table-row">
                        <td>Számlasorszám:</td>
                        <td>{order?.invoice_number}</td>
                    </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    </>
}

export default OrderDetail