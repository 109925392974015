import {Autocomplete, TextField} from "@mui/material";
import React from "react";


const CustomAutocomplete = ({errors, setErrors, data, setData, name, validators, fieldToUse, sideEffect, ...rest}) => {

    const handleChange = (newValue) => {
        const valueToSet = fieldToUse && newValue ? newValue[fieldToUse] : newValue
        console.log(`valueToSet is: ${valueToSet} beacuse fieldToUse was ${fieldToUse}. newValue is ${JSON.stringify(newValue)}`)
        setData({...data, [name]: valueToSet})
        if (sideEffect) sideEffect(valueToSet)
    };

    const handleBlur = (newValue) => {
        for (let validator of validators[name]) {
            const {valid, error} = validator(newValue)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: ''})
        }
    };

    return <Autocomplete
        name={name}
        value={data[name]}
        onChange={(e, newValue) => {
            handleBlur(newValue)
            handleChange(newValue)
        }}
        onBlur={e => handleBlur(e.target.value)}
        error={Boolean(errors[name]) && errors[name]}
        helperText={errors[name] && errors[name]}
        renderInput={(params) => <TextField error={Boolean(errors[name]) && errors[name]} helperText={errors[name] && errors[name]} label={rest?.label} {...params}/>}
        {...rest}
    />
}

export default CustomAutocomplete
