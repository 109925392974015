import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Stack, TextField} from "@mui/material";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import {GridActionsCellItem} from "@mui/x-data-grid";
import TrackingDialog from "../../components/TrackingDialog";
import ConnectionData from "./ConnectionData";
import WaybillData from "./WaybillData";
import AuthContext from "../../context/AuthContext";
import useCallDataApi from "../../hooks/data";
import {useSnackbar} from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VignetteIcon from '@mui/icons-material/Vignette';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {cleanData, getFormData} from "../../utils/FormUtils";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import OrderDetail from "./OrderDetail";
import DinoDialog from "../../components/Dialog";
import {createMap} from "../../utils/NumberUtils";
import StickerData from "./StickerData";
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import {determineAddress, orderStatusMap} from "../../utils/utils";
import ComposeInvoice from "./invoice/composeInvoice";
import SelectDiscount from "./SelectDiscount";
import DataGridWithSearch from "../../components/DataGridWithSearch";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import OrderForm from "./OrderData2";


const Orders = () => {
    const {getData: fetchOrders, getFile, createData, updateData, deleteData, postData} = useCallDataApi("orders");

    const {getData: getCouriers} = useCallDataApi("couriers")

    const {updateData: editWaybill} = useCallDataApi("waybills");
    const {updateData: editSticker} = useCallDataApi("sticker");
    const {updateData: editDoubleSticker} = useCallDataApi("double-sticker");

    const {getData: getTourOffices} = useCallDataApi("tour-offices");
    const {getData: getTourGuides} = useCallDataApi("tour-guides");
    const {getData: getTransmitters} = useCallDataApi("transmitters");
    const {getData: getReceivers} = useCallDataApi("receivers");

    const [orders, setOrders] = useState([]);
    const [tourOffices, setTourOffices] = useState({list: [], obj: {}});
    const [tourGuides, setTourGuides] = useState({list: [], obj: {}});
    const [couriers, setCouriers] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [transmitters, setTransmitters] = useState([]);
    const [isOrderDialogOpen, toggleOrderDialog] = useState(false);
    const [isEditOrderDialogOpen, toggleEditOrderDialog] = useState(false);
    const [isEditWaybillDialogOpen, toggleEditWaybillDialog] = useState(false);
    const [isEditStickerDialogOpen, toggleEditStickerDialog] = useState(false);
    const [isDeleteOrderDialogOpen, toggleDeleteOrderDialog] = useState(false);
    const [isDetailOrderDialogOpen, toggleDetailOrderDialog] = useState(false);
    const [isCloseOrderDialogOpen, toggleCloseOrderDialog] = useState(false);
    const [isOpenOrderDialogOpen, toggleOpenOrderDialog] = useState(false);
    const [isCourierOrderDialogOpen, toggleCourierOrderDialog] = useState(false);
    const [isTrackingDialogOpen, toggleTrackingDialog] = useState(false);
    const [isValidateDialogOpen, toggleValidateDialog] = useState(false);
    const [isInvoiceDialogOpen, toggleInvoiceDialog] = useState(false);
    const [isDiscountDialogOpen, toggleDiscountDialog] = useState(false);
    const [current, setCurrent] = useState(0);
    const [tracking, setTracking] = useState([])
    const [loading, setLoading] = useState(true)

    const newOrderForm = useRef(null);
    const editOrderForm = useRef(null);
    const editWaybillForm = useRef(null);
    const connectionForm = useRef(null);
    const reasonForm = useRef(null);
    const editStickerForm = useRef(null);
    const invoiceForm = useRef(null);
    const invoiceItemForm = useRef(null);
    const discountRef = useRef(null);

    const {permissions} = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();


    const getTracking = (id) => fetchOrders(`${id}/track`).then(t => setTracking(t))

    const createInvoiceManually = (id, data) => {
        setLoading(true)
        postData(`${id}/create_invoice_manually`, data).then(editedOrder => {
            const editedOrderList = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrderList)
            enqueueSnackbar('Számla elkészítve!', {variant: 'success'})
        }).finally(r => setLoading(false))
    }

    const getOrder = (id) => orders.find(o => o.id === id)

    const newOrder = (orderData) => {
        createData(orderData).then(
            newOrder => {
                setOrders(orders => [newOrder, ...orders])
                enqueueSnackbar('Megrendelés elmentve!', {variant: 'success'})
            }
        )
    }

    const editOrder = (orderData) => {
        updateData(current, orderData).then(editedOrder => {
            const editedOrderList = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrderList)
            enqueueSnackbar('Megrendelés szerkesztve!', {variant: 'success'})
        })
    }

    const deleteOrder = () => {
        deleteData(current).then(() => {
            setOrders(orders.filter(order => (order.id !== current)))
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const changeWaybill = (id, data) => {
        editWaybill(id, data).then(() => enqueueSnackbar('Szerkesztve!', {variant: 'success'}))
    }

    // Open or close the given order
    const toggle = (id, reason) => {
        let isClosed = false
        postData(`${id}/toggle`, {reason: reason}).then(editedOrder => {
            isClosed = editedOrder.closed
            const editedOrderList = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrderList)
            enqueueSnackbar((isClosed ? "Lezárva!" : "Kinyitva!"), {variant: 'success'})
        })
    }

    const invalidate = (id) => {
        fetchOrders(`${id}/invalidate`).then(() => {
            const editedOrderList = orders.filter(order => (current !== order.id))
            setOrders(editedOrderList)
            enqueueSnackbar("Érvénytelenítve!", {variant: 'success'})
        })
    }

    useEffect(() => {
        const getData = async () => {
            const [o, t, g, co, tx, rx] = await Promise.all([
                fetchOrders(),
                getTourOffices(),
                getTourGuides(),
                getCouriers(),
                getTransmitters(),
                getReceivers()
            ])
            if (o) setOrders(o)
            if (t) setTourOffices({list: t, obj: createMap(t)})
            if (g) setTourGuides({list: g, obj: createMap(g)})
            if (co) setCouriers(co)
            if (tx) setTransmitters(tx)
            if (rx) setReceivers(rx)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEditWaybill = () => {
        const formData = getFormData(editWaybillForm.current)
        changeWaybill('set_by_order', {order_id: current, waybill_data: formData})
    }

    const handleEditStickers = () => {
        setLoading(true)
        let formData = getFormData(editStickerForm.current)
        Promise.all([
            editSticker('set_by_order', {order_id: current, data: formData}),
            editDoubleSticker('set_by_order', {order_id: current, data: formData})
        ])
            .then(() => enqueueSnackbar('Matrica szerkesztve!', {variant: 'success'}))
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
    }

    const copyOrderData = (order_id) => {
        setLoading(true)
        fetchOrders(`${order_id}/manual_email_text`)
            .then(r => {
                navigator.clipboard.writeText(r?.text)
                enqueueSnackbar('Másolva!', {variant: 'success'})
            })
            .finally(() => setLoading(false))
    }

    const handleSetCourier = () => {
        let formData = getFormData(connectionForm.current)
        editOrder(formData)
    }

    const downloadInvoice = (id) => {
        setLoading(true)
        getFile(`${id}/download_invoice`, 'pdf', `INVOICE_${id}`)
            .then(r => console.log(r))
            .finally(() => setLoading(false))
    }

    const downloadQrCode = (id) => {
        setLoading(true)
        getFile(`${id}/get_qr_code`, 'png', `QR_CODE_${id}`)
            .then(r => console.log(r))
            .finally(() => setLoading(false))
    }

    const printZebra = async (id) =>
    {
        fetchOrders(`${id}/get_qr_code_zlp`).then(r => {
            console.log(r)
            const url = `http://${r?.printer_ip}/pstprnt`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Length': r?.zpl.length.toString(),
                    'Content-Type': 'application/x-www-form-urlencoded' // adjust as necessary
                },
                body: r?.zpl
            })

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Length': r?.zpl2.length.toString(),
                    'Content-Type': 'application/x-www-form-urlencoded' // adjust as necessary
                },
                body: r?.zpl2
            })
        })


    }

    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => {
                let menu = []
                if (permissions.includes('view_order') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<ContentCopyRoundedIcon/>}
                    label="Rendelés adatainak másolása"
                    onClick={() => copyOrderData(params.row.id)}
                    showInMenu={true}/>)
                if (permissions.includes('delete_order') && !params.row['closed']) menu.push(<GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleDeleteOrderDialog(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />)
                if (permissions.includes('change_order') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szerkesztés"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditOrderDialog(true)
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('change_waybill') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szállítólevél szerkesztése"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditWaybillDialog(true)
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('change_sticker') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Matrica szerkesztése"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditStickerDialog(true)
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('view_waybill')) menu.push(<GridActionsCellItem
                    icon={<LocalShippingIcon/>}
                    label="Szállítólevél"
                    onClick={() => {
                        getFile(`${params.row.id}/waybill`, 'pdf', params.row['order_number'] + '-waybill').then(r => console.log(r))
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('view_sticker')) menu.push(<GridActionsCellItem
                    icon={<VignetteIcon/>}
                    label="Matrica"
                    onClick={() => {
                        getFile(`${params.row.id}/sticker`, 'pdf', params.row['order_number'] + '-sticker').then(r => console.log(r))
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('view_doublesticker')) menu.push(<GridActionsCellItem
                    icon={<VignetteIcon/>}
                    label="Dupla matrica"
                    onClick={() => {
                        getFile(`${params.row.id}/double_sticker`, 'pdf', params.row['order_number'] + '-double-sticker').then(r => console.log(r))
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('change_order') && !params.row["closed"]) menu.push(
                    <GridActionsCellItem
                        icon={<ManageAccountsIcon/>}
                        onClick={() => {
                            setCurrent(params.row.id)
                            toggleCourierOrderDialog(true)
                        }}
                        label="Futár beállítása"
                        showInMenu={true}
                    />)
                if (permissions.includes('can_track_order')) menu.push(<GridActionsCellItem
                    icon={<AccountTreeIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        getTracking(params.row["id"])
                        toggleTrackingDialog(true)
                    }}
                    label="Nyomonkövetés"
                    showInMenu={true}
                />)
                if (permissions.includes('can_open_order') && permissions.includes('can_close_order')) menu.push(
                    <GridActionsCellItem
                        icon={params.row["closed"] ? <LockOpenIcon/> : <LockIcon/>}
                        onClick={() => {
                            setCurrent(params.row["id"])
                            params.row["closed"] ? toggleOpenOrderDialog(true) : toggleCloseOrderDialog(true)
                        }}
                        label={params.row["closed"] ? "Kinyitás" : "Lezárás"}
                        showInMenu={true}
                    />)
                if (permissions.includes('can_validate_order')) menu.push(<GridActionsCellItem
                    icon={<CloseIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleValidateDialog(true)
                    }}
                    label="Érvénytelenítés"
                    showInMenu={true}
                />)
                if (permissions.includes('change_order') && !params.row["invoice_number"]) menu.push(
                    <GridActionsCellItem
                        icon={<ReceiptRoundedIcon/>}
                        onClick={() => {
                            setCurrent(params.row["id"])
                            toggleInvoiceDialog(true)
                        }}
                        label="Számla kiállítása"
                        showInMenu={true}
                    />)
                if (permissions.includes('change_order') && params.row["invoice_number"]) menu.push(
                    <GridActionsCellItem
                        icon={<CloudDownloadRoundedIcon/>}
                        onClick={() => {
                            setCurrent(params.row["id"])
                            downloadInvoice(params.row["id"])
                        }}
                        label="Számla letöltése"
                        showInMenu={true}
                    />)
                if (permissions.includes('view_order')) menu.push(<GridActionsCellItem
                    icon={<QrCodeRoundedIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        downloadQrCode(params.row["id"])
                    }}
                    label="QR kód letöltése"
                    showInMenu={true}
                />)
                if (permissions.includes('view_order') && !params.row["closed"]) menu.push(
                    <GridActionsCellItem
                        icon={<PrintRoundedIcon/>}
                        onClick={() => {
                            setCurrent(params.row["id"])
                            printZebra(params.row["id"])
                        }}
                        label="Online waybill nyomtatása"
                        showInMenu={true}
                    />)
                if (permissions.includes('change_order')) menu.push(<GridActionsCellItem
                    icon={<LocalOfferRoundedIcon/>}
                    onClick={() => {
                        setCurrent(params.row?.id)
                        toggleDiscountDialog(true)
                    }}
                    label="Kedvezmény kiválasztása"
                    showInMenu={true}
                />)
                if (permissions.includes('change_order')) menu.push(<GridActionsCellItem
                    icon={<SendRoundedIcon/>}
                    onClick={() => {
                        postData('send_confirmation_email', {order_id: params.row?.id})
                            .then(r => {
                                r?.sent ? enqueueSnackbar('Email kiküldve!', {variant: 'success'}) : enqueueSnackbar('Nincs megadva rendelő!', {variant: 'warning'})
                            })
                            .catch(() => enqueueSnackbar('Sikertelen küldés!', {variant: 'error'}))
                    }}
                    label="Megerősítő e-mail kiküldése"
                    showInMenu={true}
                />)

                return menu
            },
        },
        {field: "order_number", headerName: "Rendelés szám", width: 140},
        {field: "comment", headerName: "Megjegyzés", width: 130},
        {field: "internal_comment", headerName: "Belsős megjegyzés", width: 130},
        {field: "order_status", headerName: "Állapot", width: 130, renderCell: p => orderStatusMap[p.row.order_status]},
        {field: "tour_type", headerName: "Program", width: 110},
        {
            field: "tour_office", headerName: "Megrendelő", width: 110, valueGetter: (params) => {
                const to = tourOffices.obj[params.row.tour_office]
                if (to) return to?.name || to?.short_name
                return ''
            }
        },
        {field: "tour_code", headerName: "Megnevezés", width: 110},
        {
            field: "tour_guide", headerName: "Csoportkísérő", width: 110, valueGetter: (params) => {
                const tg = tourGuides.obj[params.row.tour_guide]
                if (tg) return `${tg?.last_name} ${tg?.first_name}`
                return ''
            }
        },
        {field: "delivery_date", type: "date", headerName: "Kiszállítás dátum", width: 200},
        {
            field: "delivery_time",
            type: "string",
            headerName: "Kiszállítás idő",
            width: 200,
            renderCell: (params) => (params.row.delivery_time?.slice(0, 5))
        },
        {field: "delivery_period", type: "string", headerName: "Mikor", width: 50},
        {field: "days_before", headerName: "Előtte", width: 110},
        {field: "delivery_type", headerName: "Kiszállítás típusa", width: 110},
        {
            field: "delivery_address",
            headerName: "Kiszállítási cím",
            width: 250,
            valueGetter: p => determineAddress(p.row?.delivery_address)
        },
        {field: "delivery_format", headerName: "Delivery format", width: 110},
        {field: "return_date", type: "date", headerName: "Vissza dátum", width: 200},
        {
            field: "return_time",
            type: "string",
            headerName: "Vissza idő",
            width: 200,
            renderCell: (params) => (params.row.return_time?.slice(0, 5))
        },
        {field: "days_after", headerName: "Utána", width: 110},
        {field: "return_type", headerName: "Visszaszállítás típusa", width: 110},
        {
            field: "return_address",
            headerName: "Vissza cím",
            width: 250,
            valueGetter: p => determineAddress(p.row?.return_address)
        },
        {field: "returned_rx", headerName: "Visszérkezett RX", width: 110},
        {field: "returned_tx", headerName: "Visszaérkezett TX", width: 110},
        {
            field: "receiver",
            headerName: "Vevő",
            width: 110,
            valueGetter: p => receivers.find(r => r.id === p.row.receiver)?.name
        },
        {
            field: "transmitter",
            headerName: "Adó",
            width: 110,
            valueGetter: p => transmitters.find(t => t.id === p.row.transmitter)?.name
        },
        {field: "order_rx", headerName: "Rendelt RX", width: 110},
        {field: "order_tx", headerName: "Rendelt TX", width: 110},
        {field: "sent_rx", headerName: "Elküldött RX", width: 110},
        {field: "sent_tx", headerName: "Elküldött TX", width: 110},
        {field: "invoice", headerName: "Számla típusa", width: 110},
        {field: "invoice_number", headerName: "Számlasorszám", width: 110},
        {field: "DHL_delivery_number", headerName: "Oda fuvarlevélszám", width: 110},
        {field: "DHL_return_number", headerName: "Vissza fuvarlevélszám", width: 110},
        {field: "DHL_order", headerName: "Oda felvételazonosító", width: 110},
        {field: "DHL_order_back", headerName: "Vissza felvételazonosító", width: 110},
        {
            field: "delivery_courier",
            headerName: "Kiszállító futár",
            width: 140,
            valueGetter: p => couriers.find(c => c.id === p.row.delivery_courier)?.name || '-'
        },
        {
            field: "return_courier",
            headerName: "Visszaszállító futár",
            width: 140,
            valueGetter: p => couriers.find(c => c.id === p.row.return_courier)?.name || '-'
        },
        {field: "closed", headerName: "Lezárva", type: "boolean", width: 140},
        {field: "wrong", headerName: "Problémás", type: "boolean", width: 140},
        // {field: "price", headerName: "Ár", type: "number", width: 140},
        {field: "currency", headerName: "Pénznem", width: 110},
        {field: "days", headerName: "Napok száma", type: "number", width: 140},
        {
            field: "short_rent_HUF", headerName: "Rövid bérlés Forintban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.short_rent_HUF
                return ''
            }, width: 200
        },
        {
            field: "long_rent_HUF", headerName: "Hosszú bérlés Forintban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.long_rent_HUF
                return ''
            }, width: 200
        },
        {
            field: "short_rent_EUR", headerName: "Rövid bérlés Euróban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.short_rent_EUR
                return ''
            }, width: 200
        },
        {
            field: "long_rent_EUR", headerName: "Hosszú bérlés Euróban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.long_rent_EUR
                return ''
            }, width: 200
        },
    ]

    const onRowClick = (params) => {
        setCurrent(params['id'])
        toggleDetailOrderDialog(true)
    }

    const newDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleOrderDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            const formData = newOrderForm.current.getData()
            if (Object.entries(formData).length > 0) {
                newOrder(formData)
                toggleOrderDialog(false)
            }
        }}>Hozzáadás</Button>
    </Stack>

    const editDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleEditOrderDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            const formData = editOrderForm.current.getData()
            if (Object.entries(formData).length > 0) {
                editOrder(formData)
                toggleEditOrderDialog(false)
            }
        }}>Szerkesztés</Button>
    </Stack>

    const deleteDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleDeleteOrderDialog(false)}>Mégsem</Button>
        <Button size='large' variant='contained' color='error' onClick={() => {
            toggleDeleteOrderDialog(false)
            deleteOrder()
        }}>törlés</Button>
    </Stack>

    return (
        <div>
            <PersistentDrawerLeft title="Megrendelések"
                                  buttons={[<Button disabled={!permissions?.includes('add_order')} variant='outlined'
                                                    endIcon={<AddCircleOutlineRoundedIcon/>}
                                                    onClick={() => toggleOrderDialog(true)} color='success'>Új megrendelés</Button>]}>

                <DataGridWithSearch name='orders' onRowClick={onRowClick} rows={orders} columns={
                    columns.map(col => ({
                        ...col,
                        cellClassName: (params) => (`closed-${params.row['closed']} status-${params.row['order_status']}`)
                    }))}/>

                <Loading isLoading={loading}/>


                <DinoDialog
                    title="Új megrendelés"
                    open={isOrderDialogOpen}
                    handleClose={() => toggleOrderDialog(false)}
                    actions={newDialogActions}>
                    <OrderForm reference={newOrderForm}/>
                </DinoDialog>

                <DinoDialog
                    title={getOrder(current)?.order_number + " szerkesztése"}
                    open={isEditOrderDialogOpen}
                    handleClose={() => toggleEditOrderDialog(false)}
                    actions={editDialogActions}>
                    <OrderForm
                        reference={editOrderForm}
                        order={getOrder(current)}/>
                </DinoDialog>

                <DinoDialog
                    title="Szállítólevél szerkesztése"
                    open={isEditWaybillDialogOpen}
                    handleClose={() => (toggleEditWaybillDialog(false))}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleEditWaybillDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            handleEditWaybill()
                            toggleEditWaybillDialog(false)
                        }}>Beállítás</Button>
                    </Stack>}>
                    <WaybillData reference={editWaybillForm} waybill={current}/>
                </DinoDialog>

                <DinoDialog
                    title="Matrica szerkesztése"
                    open={isEditStickerDialogOpen}
                    handleClose={() => (toggleEditStickerDialog(false))}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleEditStickerDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            handleEditStickers()
                            toggleEditStickerDialog(false)
                        }}>Beállítás</Button>
                    </Stack>}>
                    <StickerData reference={editStickerForm} sticker={current}/>
                </DinoDialog>

                <DinoDialog
                    title="Futár beállítása"
                    open={isCourierOrderDialogOpen}
                    handleClose={() => (toggleCourierOrderDialog(false))}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleCourierOrderDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            handleSetCourier()
                            toggleCourierOrderDialog(false)
                        }}>Beállítás</Button>
                    </Stack>}>
                    <ConnectionData reference={connectionForm} order={getOrder(current)}/>
                </DinoDialog>

                <TrackingDialog
                    model={getOrder(current)?.order_number}
                    open={isTrackingDialogOpen}
                    onClose={() => (toggleTrackingDialog(false))}
                    trackingInfo={tracking}/>

                <DinoDialog
                    handleClose={() => toggleDeleteOrderDialog(false)}
                    actions={deleteDialogActions}
                    open={isDeleteOrderDialogOpen}
                    title={`Biztosan törölni akarja: ${getOrder(current)?.order_number}?`}
                    subtitle='Minden kapcsolódó adat elvész.'/>

                <DinoDialog
                    handleClose={() => toggleDetailOrderDialog(false)}
                    open={isDetailOrderDialogOpen}
                    title={getOrder(current)?.order_number}
                    subtitle='Megrendelés'
                >
                    <OrderDetail orderId={current}/>
                </DinoDialog>

                <DinoDialog
                    handleClose={() => toggleValidateDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleValidateDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='error' onClick={() => {
                            toggleValidateDialog(false)
                            invalidate(current)
                        }}>érvénytelenítés</Button>
                    </Stack>}
                    open={isValidateDialogOpen}
                    title={`Biztosan érvényteleníti: ${getOrder(current)?.order_number}?`}
                    subtitle='Csak admin felhasználó tudja ezt visszavonni.'/>

                <DinoDialog
                    handleClose={() => toggleCloseOrderDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleCloseOrderDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='error' onClick={() => {
                            toggleCloseOrderDialog(false)
                            toggle(current, '')
                        }}>Lezárás</Button>
                    </Stack>}
                    open={isCloseOrderDialogOpen}
                    title={`Biztosan lezárja ezt: ${getOrder(current)?.order_number}?`}
                    subtitle='Indoklás után kinyitható újra.'/>

                <DinoDialog
                    handleClose={() => toggleInvoiceDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleInvoiceDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            const data = invoiceForm.current.getData()
                            const itemData = invoiceItemForm.current.getItems()
                            const hasItems = itemData.length > 0;
                            if (!hasItems) enqueueSnackbar('Nincsenek tételek hozzáadva a számlához!', {variant: 'error'})
                            if (Object.entries(data).length > 0 && hasItems) {
                                createInvoiceManually(current, {...data, extra_items: itemData})
                                toggleInvoiceDialog(false)
                            }
                        }} endIcon={<SaveRoundedIcon/>}>Mentés</Button>
                    </Stack>}
                    open={isInvoiceDialogOpen}
                    title={`Számla kiállítás ehhez: ${getOrder(current)?.order_number}?`}
                    subtitle='Számla kiállítás a billingó rendszerén keresztül'>
                    <ComposeInvoice referenceGeneral={invoiceForm} referenceItems={invoiceItemForm} obj={getOrder(current)}/>
                </DinoDialog>


                <DinoDialog
                    handleClose={() => toggleDiscountDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleDiscountDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            const selected = discountRef.current.getSelected()
                            editOrder({discount: selected})
                            toggleDiscountDialog(false)
                        }} endIcon={<SaveRoundedIcon/>}>Mentés</Button>
                    </Stack>}
                    open={isDiscountDialogOpen}
                    title={`Kedvezmény kiválasztása ehhez: ${getOrder(current)?.order_number}?`}
                    subtitle='A kedvezmény a végösszeget fogja megváltoztatni a számlán'>
                    <SelectDiscount order_id={current} reference={discountRef}/>
                </DinoDialog>

                <DinoDialog
                    title={`Biztosan kinyitja ezt: ${getOrder(current)?.order_number}?`}
                    open={isOpenOrderDialogOpen}
                    handleClose={() => toggleOpenOrderDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleCloseOrderDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='error' onClick={() => {
                            toggleOpenOrderDialog(false)
                            let reason = getFormData(reasonForm.current)?.reason
                            if (reason !== undefined) toggle(current, reason)
                        }}>Kinyitás</Button>
                    </Stack>}>
                    <form ref={reasonForm} onSubmit={(event) => {
                        event.preventDefault()
                        toggleOpenOrderDialog(false)
                        let reason = getFormData(reasonForm.current).reason
                        toggle(current, reason)
                    }}>
                        <Stack spacing={2}>
                            <TextField required={true} name="reason" id="reason" label="Indoklás"/>
                        </Stack>
                    </form>
                </DinoDialog>

            </PersistentDrawerLeft>
        </div>
    );
};

export default Orders;
