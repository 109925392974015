import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    GridCsvExportMenuItem,
    gridFilteredSortedRowIdsSelector,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    useGridApiContext,
} from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import * as XLSX from 'xlsx';
import {DataGridPro, huHU, LicenseInfo} from "@mui/x-data-grid-pro";
import useKeyPress from "../hooks/keyListener";
import {InputAdornment} from "@mui/material";
import { styled } from '@mui/material/styles';


const getExcelData = (apiRef) => {
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumns = apiRef.current.getVisibleColumns();

    return filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumns.forEach((col) => {
            row[col.headerName] = apiRef.current.getCellParams(id, col.field).value;
        });
        return row;
    });
};

const exportToExcel = (rows, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, filename);
};

const ExcelExportMenuItem = (props) => {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                const excelData = getExcelData(apiRef);
                exportToExcel(excelData, `exported.xlsx`);
                hideMenu?.();
            }}
        >
            Export Excel
        </MenuItem>
    );
};

ExcelExportMenuItem.propTypes = {
    hideMenu: PropTypes.func,
};

const csvOptions = { delimiter: ';' };

const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
        <ExcelExportMenuItem />
    </GridToolbarExportContainer>
);

const ShortcutAdornment = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontWeight: 500,
}));

const CustomToolbar = (props) => {
    const searchRef = useRef(null)
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const keyCombination = isMac ? ['Meta', 'k'] : ['Control', 'k']
    useKeyPress(keyCombination, () => searchRef.current?.focus())


    return <GridToolbarContainer style={{display: 'flex', justifyContent: 'space-between'}} {...props}>
        <div style={{display: 'flex'}}>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <CustomExportButton/>
        </div>
        <GridToolbarQuickFilter inputProps={{ref: searchRef}}/>
    </GridToolbarContainer>
}

const DataGridWithSearch = ({rows, columns, name, onRowClick}) => {
    const [pageSize, setPageSize] = useState(50)
    const [visibility, setVisibility] = useState({})
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const divRef = useRef(null)

    const customLocaleText = {
        ...huHU.components.MuiDataGrid.defaultProps.localeText,
        toolbarQuickFilterPlaceholder: `Keresés... (${isMac ? '⌘+K' : 'ctrl+k'})`,
    }

    const reorderColumns = (columns, order) => {
        const orderMap = new Map(order.map((str, index) => [str, index]));
        return columns.sort((a, b) => orderMap.get(a.field) - orderMap.get(b.field));
    }

    const determineColumnOrder = () => {
        const columnOrder = localStorage.getItem(`columnOrder-${name}`)
        if (!columnOrder) return columns
        return reorderColumns(columns, JSON.parse(columnOrder))
    }

    const data = {
        rows: rows,
        columns: determineColumnOrder(),
        rowLength: 10,
        maxColumns: 10,
    }

    useEffect(() => {
        const v = localStorage.getItem(`columnVisibility-${name}`)
        setVisibility(v ? JSON.parse(v) : {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleColumnOrderChange = (c, e, d) => {
        const {columns} = d.api.exportState()
        localStorage.setItem(`columnOrder-${name}`, JSON.stringify(columns.orderedFields))
    }

    const handleVisibilityModelChange = (c, e) => {
        setVisibility(c)
        localStorage.setItem(`columnVisibility-${name}`, JSON.stringify(c))
    }

    const handleOnRowClick = (param) => {
        if (onRowClick) onRowClick(param.row)
    }

    const boundingClientRect = divRef.current?.getBoundingClientRect();
    const padding = 30;

    return (
        <div style={{ height: `calc(100vh - ${(boundingClientRect?.y || 0) + padding}px)`, width: '100%' }} ref={divRef}>
            <DataGridPro
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[30, 50, 100]}
                pagination
                {...data}
                components={{ Toolbar: CustomToolbar }}
                disableSelectionOnClick
                density="compact"
                localeText={customLocaleText}
                onRowClick={handleOnRowClick}
                onColumnOrderChange={handleColumnOrderChange}
                onColumnVisibilityModelChange={handleVisibilityModelChange}
                columnVisibilityModel={visibility}
            />
        </div>
    );
}

export default DataGridWithSearch
