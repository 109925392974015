import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingFrom";
import React, {useState} from "react";
import {Stack, Typography, useTheme} from "@mui/material";
import CustomTextField from "../../components/formValidation/CustomTextField";
import {validateEmail, validateRequired} from "../../components/formValidation/validators";

const NewContactPersonData = ({reference, obj}) => {
    const theme = useTheme()
    const [formData, setFormData] = useState({
        first_name: '' || obj?.first_name,
        last_name: '' || obj?.last_name,
        phone: '' || obj?.phone,
        email: '' || obj?.email,
    })

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
    })

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        phone: [],
        email: [validateRequired, validateEmail],
    }
    return <ErrorHandlingForm reference={reference} errors={errors} setErrors={setErrors} data={formData} setData={setFormData} validators={validators}>
        <Stack spacing={2}>
            <Typography variant='caption' color={theme.palette.primary[500]}>Keresztnév*</Typography>
            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="first_name"
                label='Keresztnév'/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Vezetéknév*</Typography>
            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="last_name"
                label='Vezetéknév'/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Telefonszám</Typography>
            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="phone"
                label='Telefonszám'/>
            <Typography variant='caption' color={theme.palette.primary[500]}>Email*</Typography>
            <CustomTextField
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name="email"
                label='Email'/>

        </Stack>
    </ErrorHandlingForm>
}

export default NewContactPersonData