import PersistentDrawerLeft from "../../components/NewDrawer";
import useCallDataApi from "../../hooks/data";
import React, {useContext, useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import AuthContext from "../../context/AuthContext";
import DinoDataGrid from "../../components/DataGrid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DinoDialog from "../../components/Dialog";
import {Button, Stack} from "@mui/material";
import {cleanData} from "../../utils/FormUtils";
import {useSnackbar} from "notistack";
import CouponCodeData from "./CouponCodeData";
import {thousandSeparator} from "../../utils/NumberUtils";
import DataGridWithSearch from "../../components/DataGridWithSearch";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

const CouponCodes = () => {
    const {permissions} = useContext(AuthContext)
    const {getData: fetchCouponCodes, deleteData, updateData, createData} = useCallDataApi('coupon-codes')
    const [users, setUsers] = useState([])
    const [couponCodes, setCouponCodes] = useState([])
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(-1)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [newDialogOpen, setNewDialogOpen] = useState(false)
    const couponCodeForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        setLoading(true)
        fetchCouponCodes().then(c => setCouponCodes(c)).finally(() => setLoading(false))
        fetchCouponCodes('get_users').then(u => setUsers(u))
    }, [])

    const getUser = id => users.find(c => c?.id === id)

    const getCouponCode = id => couponCodes.find(c => c?.id === id)

    const deleteCouponCode = id => {
        setLoading(true)
        deleteData(id).then(r => setCouponCodes([...couponCodes.filter(c => c?.id !== id)])).finally(() => setLoading(false))
    }

    const editCouponCode = data => {
        setLoading(true)
        updateData(current, data)
            .then(c => {
                const index = couponCodes.findIndex(coupon => coupon.id === current);
                if (index !== -1) {
                    const updatedCouponCodes = [...couponCodes]
                    updatedCouponCodes[index] = c
                    setCouponCodes(updatedCouponCodes)
                    enqueueSnackbar('Szerkesztve!', {variant: 'success'})
                }
            })
            .catch(r => enqueueSnackbar('Váratlan hiba történt!', {variant: 'error'}))
            .finally(() => setLoading(false))
    }

    const newCouponCode = (data) => {
        setLoading(true)
        createData(data)
            .then(c => {
                setCouponCodes([...couponCodes, c])
                enqueueSnackbar('Hozzáadva!', {variant: 'success'})
            })
            .catch(r => enqueueSnackbar('Váratlan hiba történt!', {variant: 'error'}))
            .finally(() => setLoading(false))
    }

    const getUsersNames = (ids) => {
        if (ids?.length === 0) return 'Minden felhasználó'
        return ids.map(id => {
            const user = getUser(id);
            return user ? user.name + ' ' : 'User not found';
        });
    }

    const columns = [
        {
            field: "manage",
            headerName: "Kezelés",
            sortable: false,
            type: "actions",
            getActions: (params) => {
                let menu = []
                if (permissions.includes('delete_couponcode')) menu.push(<GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row.id)
                        setDeleteDialogOpen(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />)

                if (permissions.includes('change_couponcode')) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szerkesztés"
                    onClick={() => {
                        setCurrent(params.row.id)
                        setEditDialogOpen(true)
                    }}
                    showInMenu={true}/>)
                return menu
            },
        },
        {field: "name", headerName: "Név", width: 130},
        {field: "valid_thru", headerName: "Érvényesség", width: 130, type: 'date'},
        {
            field: "percent",
            headerName: "Százalék",
            width: 130,
            renderCell: p => `${p.row.percent || '-'} %`,
            type: 'number'
        },
        {
            field: "minus",
            headerName: "Kedvezmény összege",
            width: 200,
            renderCell: p => `${thousandSeparator(p.row.minus) || '-'} Ft`,
            type: 'number'
        },
        {field: "allowed_users", headerName: "Jogosult felhasználók", width: 200, renderCell: p => getUsersNames(p.row.allowed_users)},
    ];

    const addCouponCode = () => {
        const data = couponCodeForm.current.getData()
        if (Object.entries(data).length > 0) {
            newCouponCode(data)
            setNewDialogOpen(false)
        }
    }

    const updateCouponCode = () => {
        const data = couponCodeForm.current.getData()
        if (Object.entries(data).length > 0) {
            editCouponCode(data)
            setEditDialogOpen(false)
        }
    }

    return <PersistentDrawerLeft title='Kuponkódok' buttons={[<Button disabled={!permissions?.includes('add_couponcode')} variant='outlined' endIcon={<AddCircleOutlineRoundedIcon/>} onClick={() => setNewDialogOpen(true)} color='success'>Új kuponkód</Button>]}>
        <DataGridWithSearch name='coupon-codes' rows={couponCodes} columns={columns}/>
        {/*{permissions.includes('add_couponcode') && <BasicSpeedDial onClick={() => setNewDialogOpen(true)}/>}*/}
        <Loading isLoading={loading}/>

        <DinoDialog
            title={`${getCouponCode(current)?.name} törlése`}
            open={deleteDialogOpen}
            handleClose={() => setDeleteDialogOpen(false)}
            subtitle='Ez a művelet nem vonható vissza'
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setDeleteDialogOpen(false)}>Mégsem</Button>
                <Button variant='outlined' onClick={() => {
                    setDeleteDialogOpen(false)
                    deleteCouponCode(current)
                }} color='error'>Törlés</Button>
            </Stack>}/>

        <DinoDialog
            title={`${getCouponCode(current)?.name} szerkesztése`}
            open={editDialogOpen}
            handleClose={() => setEditDialogOpen(false)}
            subtitle='Kuponkód szerkesztése'
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setEditDialogOpen(false)}>Mégsem</Button>
                <Button variant='outlined' onClick={updateCouponCode} color='success'>Szerkesztés</Button>
            </Stack>}>
            <CouponCodeData reference={couponCodeForm} obj={getCouponCode(current)} />
        </DinoDialog>

        <DinoDialog
            title='Új kuponkód'
            open={newDialogOpen}
            handleClose={() => setNewDialogOpen(false)}
            subtitle='Kuponkód hozzáadása'
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setNewDialogOpen(false)}>Mégsem</Button>
                <Button variant='outlined' onClick={addCouponCode} color='success'>Hozzáadás</Button>
            </Stack>}>
            <CouponCodeData reference={couponCodeForm}/>
        </DinoDialog>
    </PersistentDrawerLeft>
}

export default CouponCodes