import { useEffect, useState } from 'react';

const useKeyPress = (keys, callback) => {
    const [pressedKeys, setPressedKeys] = useState(new Set())

    useEffect(() => {
        const handleKeyDown = (event) => {
            setPressedKeys((prev) => {
                return new Set(prev).add(event.key)
            })
        }

        const handleKeyUp = (event) => {
            setPressedKeys((prev) => {
                const newSet = new Set(prev)
                newSet.delete(event.key)
                return newSet
            })
        }

        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('keyup', handleKeyUp)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('keyup', handleKeyUp)
        };
    }, []);

    useEffect(() => {
        if (keys.every((key) => pressedKeys.has(key))) {
            setPressedKeys(prev => new Set())
            callback()
        }
    }, [pressedKeys, keys, callback])
}

export default useKeyPress