import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import AccountCircle from "@mui/icons-material/AccountCircle";
import packageInfo from "../../package.json";
import MoreIcon from "@mui/icons-material/MoreVert";
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import ModeContext from "../context/ModeContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MopedRoundedIcon from "@mui/icons-material/MopedRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {Stack} from "@mui/material";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft({title, buttons=[], children}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let {permissions, username, logoutUser} = useContext(AuthContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { theme: mode, toggle } = useContext(ModeContext);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuItems = [
        {
            path: "/",
            icon: <HomeIcon />,
            text: "Kezelőpult",
            requiredPermission: null
        },
        {
            path: "/orders",
            icon: <HeadphonesIcon />,
            text: "Megrendelések",
            requiredPermission: "view_order"
        },
        {
            path: "/tour-offices",
            icon: <EmojiTransportationIcon />,
            text: "Megrendelők",
            requiredPermission: "view_touroffice"
        },
        {
            path: "/tour-guides",
            icon: <PeopleAltIcon />,
            text: "Csoportkísérők",
            requiredPermission: "view_tourguide"
        },
        {
            path: "/daily",
            icon: <ForwardToInboxIcon />,
            text: "Kiszállítások",
            requiredPermission: "view_daily"
        },
        {
            path: "/courier",
            icon: <MopedRoundedIcon />,
            text: "Futárok",
            requiredPermission: "view_daily"
        },
        {
            path: "/reports",
            icon: <AssessmentIcon />,
            text: "Riportok",
            requiredPermission: "view_report"
        },
        {
            path: "/email-templates",
            icon: <AlternateEmailRoundedIcon />,
            text: "Email sablonok",
            requiredPermission: "view_emailtemplate"
        },
        {
            path: "/order-return",
            icon: <ArrowBackIosRoundedIcon />,
            text: "Visszaérkeztetés",
            requiredPermission: "view_order"
        },
        {
            path: "/coupon-codes",
            icon: <LocalOfferRoundedIcon />,
            text: "Kuponkódok",
            requiredPermission: "view_couponcode"
        },
        // {
        //     path: "/scanner",
        //     icon: <QrCodeRoundedIcon />,
        //     text: "Qr szkennelés",
        //     permissions: "view_order",
        // }
    ];


    const menuId = 'primary-search-account-menu';
    const renderMenu = () => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
            {/*<MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
            <MenuItem onClick={logoutUser}>Kijelentkezés</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = () => (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" onClick={toggle} color="inherit">
                    {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <p>Mode</p>
            </MenuItem>
            <MenuItem onClick={logoutUser}>

                <IconButton>
                    <AccountCircle />
                </IconButton>
                <p>Log out</p>
            </MenuItem>
        </Menu>
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {renderMenu()}
            {renderMobileMenu()}
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1, display: { xs: 'none', sm: 'block' }}}>
                        {title}
                    </Typography>
                    {/*    <Search>*/}
                    {/*    <SearchIconWrapper>*/}
                    {/*        <SearchIcon />*/}
                    {/*    </SearchIconWrapper>*/}
                    {/*    <StyledInputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        inputProps={{ 'aria-label': 'search' }}*/}
                    {/*    />*/}
                    {/*</Search>*/}
                    <Box sx={{ flexGrow: 1 }} />
                    {/*<Box sx={{ display: { xs: 'none', md: 'flex' } }}>*/}
                    {/*    <IconButton size="large" onClick={toggle} color="inherit">*/}
                    {/*        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}*/}
                    {/*    </IconButton>*/}
                    {/*    <IconButton*/}
                    {/*        size="large"*/}
                    {/*        edge="end"*/}
                    {/*        aria-label="account of current user"*/}
                    {/*        aria-controls={menuId}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        onClick={handleProfileMenuOpen}*/}
                    {/*        color="inherit"*/}
                    {/*    >*/}
                    {/*        <AccountCircle />*/}

                    {/*    </IconButton>*/}
                    {/*    <Typography variant="p" sx={{alignSelf: 'center', marginLeft: '15px'}}>{username} | v: {packageInfo?.version}</Typography>*/}
                    {/*</Box>*/}
                    {/*<Box sx={{ display: { xs: 'flex', md: 'none' } }}>*/}
                    {/*    <IconButton*/}
                    {/*        size="large"*/}
                    {/*        aria-label="show more"*/}
                    {/*        aria-controls={mobileMenuId}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        onClick={handleMobileMenuOpen}*/}
                    {/*        color="inherit"*/}
                    {/*    >*/}
                    {/*        <MoreIcon />*/}
                    {/*    </IconButton>*/}
                    {/*</Box>*/}
                    <Stack direction='row' spacing={1}>
                        {buttons?.map(b => b)}
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {menuItems.map((item, index) => (
                        !item.requiredPermission || permissions.includes(item.requiredPermission) ? (
                            <Link key={index} className="menu-item" to={item.path}>
                                <ListItem>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.text}</ListItemText>
                                </ListItem>
                            </Link>
                        ) : (
                            <span key={index} />
                        )
                    ))}
                    <Divider />
                </List>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <IconButton size="large" onClick={toggle} color="inherit">
                        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle />

                    </IconButton>
                    <Typography variant="p" sx={{alignSelf: 'center', marginLeft: '15px'}}>{username} | v: {packageInfo?.version}</Typography>
                </Box>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {children}
            </Main>
        </Box>
    );
}