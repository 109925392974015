const currencies = {
    HUF: 'HUF',
    EUR: 'EUR'
}

const paymentMethods = {
    ARUHITEL: 'aruhitel',
    BANKCARD: 'bankcard',
    BARION: 'barion',
    BARTER: 'barter',
    CASH: 'cash',
    CASH_ON_DELIVERY: 'cash_on_delivery',
    COUPON: 'coupon',
    ELORE_UTALAS: 'elore_utalas',
    EP_KARTYA: 'ep_kartya',
    KOMPENZACIO: 'kompenzacio',
    LEVONAS: 'levonas',
    ONLINE_BANKCARD: 'online_bankcard',
    OTHER: 'other',
    PAYLIKE: 'paylike',
    PAYONEER: 'payoneer',
    PAYPAL: 'paypal',
    PAYPAL_UTOLAG: 'paypal_utolag',
    PAYU: 'payu',
    PICK_PACK_PONT: 'pick_pack_pont',
    POSTAI_CSEKK: 'postai_csekk',
    POSTAUTALVANY: 'postautalvany',
    SKRILL: 'skrill',
    SZEP_CARD: 'szep_card',
    TRANSFERWISE: 'transferwise',
    UPWORK: 'upwork',
    UTALVANY: 'utalvany',
    VALTO: 'valto',
    WIRE_TRANSFER: 'wire_transfer'
}

const unitPriceTypes = {
    BRUTTO: 'gross',
    NETTO: 'net'
}

const units = {
    PIECE: 'darab',
    DAY: 'nap',
    MONTH: 'hónap',
}

const vat = {
    NORMAL:'27%',
    DISCOUNT_0:'0%',
    DISCOUNT_5:'5%',
    DISCOUNT_18:'18%',
}

const deliveryTypes = {
    DHL_STANDARD: 'DHL Standard',
    DHL_EXPRESS: 'DHL Express',
    GLS: 'GLS',
    TGS_OFFICE: 'TGS Iroda',
    HAND: 'Kézbe',
}

const invoiceTypes = {
    InvoiceBankTransfer: "Invoice bank transfer",
    InvoiceCash: "Invoice cash",
    Cash: "Cash",
    InvoiceCreditCard: "Invoice credit card",
    Compensation: "Compensation"
}

const deliveryPeriods = {
    EN: "EN",
    DU: "DU",
    DE: "DE",
}

const programs = {
    Budapest: "Budapest",
    Gyárlátogatás: "Gyárlátogatás",
    Külföld: "Külföld",
    Ski: "Ski",
    Hajóutak: "Hajóutak",
}

const deliveryFormats = {
    Backpack: "Backpack",
    Case: "Case",
}

const orderStatuses = {
    Pending: "Pending",
    Confirmed: "Confirmed",
    DN_Printed: "DN Printed",
    Delivered: "Delivered",
    Courier_ordered: "Courier ordered",
    Returning: "Returning",
    Returned: "Returned",
    Cancelled: "Cancelled",
    ON_THE_WAY_OUT: "ON THE WAY OUT",
}

export {
    currencies,
    paymentMethods,
    unitPriceTypes,
    units,
    vat,
    deliveryTypes,
    invoiceTypes,
    deliveryPeriods,
    programs,
    deliveryFormats,
    orderStatuses
}