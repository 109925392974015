import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Stack,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingFrom";
import {formatHuman} from "../../../utils/utils";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";
import CustomSelect from "../../../components/formValidation/CustomSelect";
import {validateDate, validateRequired} from "../../../components/formValidation/validators";
import CustomDatePicker from "../../../components/formValidation/CustomDatePicker";
import CustomAutocomplete from "../../../components/formValidation/CustomAutocomplete";
import {currencies, paymentMethods} from "../../../utils/enums";


const InvoiceGeneralData = ({reference, obj, sharedValues, setSharedValues}) => {
    const [loading, setLoading] = useState(false)
    const [tourOffices, setTourOffices] = useState([])
    const {getData: getTourOfficeData} = useCallDataApi("tour-offices")

    useEffect(() => {
        setLoading(true)
        getTourOfficeData().then(t => setTourOffices(t)).then(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [formData, setFormData] = useState({
        tour_office: obj?.tour_office || '',
        payment_method: obj?.payment_method || paymentMethods.BANKCARD,
        currency: obj?.currency || currencies.HUF,
        due_date: obj?.due_date || formatHuman(new Date()),
        fulfilment_date: obj?.return_date || formatHuman(new Date()),
        sendInvoice: obj?.sendInvoice || true,
        payed: obj?.payed || false,
    })

    useEffect(() => {
        setSharedValues({...sharedValues, currency: formData?.currency})
    }, [formData?.currency])

    const [errors, setErrors] = useState({
        tour_office: false,
        payment_method: false,
        currency: false,
        due_date: false,
        fulfilment_date: false,
        sendInvoice: false,
        payed: false,
    })

    const validators = {
        tour_office: [validateRequired],
        payment_method: [validateRequired],
        currency: [validateRequired],
        due_date: [validateRequired, validateDate],
        fulfilment_date: [validateRequired, validateDate],
        sendInvoice: [],
        payed: [],
    };

    return <ErrorHandlingForm reference={reference} errors={errors} setErrors={setErrors} data={formData}
                              setData={setFormData} validators={validators} style={{marginTop: '15px'}}>
        <Stack spacing={2}>
            <FormControl>
                <CustomAutocomplete
                    options={tourOffices}
                    getOptionLabel={(option) => {
                        if (typeof option === "number") {
                            const to = tourOffices.find(t => t?.id === option)
                            return to?.short_name || to?.name || ""
                        }
                        return option?.short_name || option?.name || ""
                    }}
                    label='Megrendelő'
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option?.short_name}
                        </li>
                    )}
                    name="tour_office"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />
            </FormControl>
            <FormControl>
                <InputLabel id="payment_method">Fizetési mód</InputLabel>
                <CustomSelect
                    labelId="payment_method"
                    label="Fizetési mód"
                    id="payment_method"
                    name="payment_method"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    {Object.values(paymentMethods).map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                </CustomSelect>
            </FormControl>
            <FormControl>
                <InputLabel id="currency">Valuta</InputLabel>
                <CustomSelect
                    labelId="currency"
                    label="Valuta"
                    id="currency"
                    name="currency"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                >
                    {Object.values(currencies).map(curr => <MenuItem key={curr} value={curr}>{curr}</MenuItem>)}
                </CustomSelect>
            </FormControl>
            <CustomDatePicker
                label="Fizetési határidő"
                inputFormat="yyyy-MM-dd"
                mask="____-__-__"
                name="due_date"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <CustomDatePicker
                label="Teljesítés dátuma"
                inputFormat="yyyy-MM-dd"
                mask="____-__-__"
                name="fulfilment_date"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox name='payed' checked={formData?.payed}
                                       onChange={e => setFormData({...formData, payed: e.target.checked})}/>}
                    label="Fizetett"/>
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox name='send_email' checked={formData?.sendInvoice}
                                       onChange={e => setFormData({...formData, sendInvoice: e.target.checked})}/>}
                    label="Számla kiküldése mentés után"/>
            </FormGroup>
        </Stack>

        <Loading isLoading={loading}/>
    </ErrorHandlingForm>
}

export default InvoiceGeneralData