import {Box, IconButton, Stack, Typography, useTheme} from "@mui/material";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {thousandSeparator} from "../../../utils/NumberUtils";

const InvoiceItem = (props) => {
    const theme = useTheme()

    const sum = () => {
        const q = parseInt(props.item.quantity)
        const up = parseInt(props.item.unit_price)
        return q * up
    }

    return <Stack direction='row' justifyContent='space-between' sx={{border: `1px solid ${theme.palette.primary.main}`, borderRadius: 4, padding: 2}}>
        <Stack>
            <Typography variant='h5'>{props.item.name}</Typography>
            <Typography variant='h6'>{props.item.quantity} {props.item.unit} - {props.item.unit_price_type} {thousandSeparator(props.item.unit_price)} {props?.sharedValues?.currency}</Typography>
            <Typography variant='h6'>ÁFA: {props.item.vat} Összesen: {thousandSeparator(sum())} {props?.sharedValues?.currency}</Typography>
            <Typography variant='body1'>{props.item?.comment}</Typography>
        </Stack>

        <Stack spacing={2}>
            <Box sx={{display: 'flex'}} justifyContent='flex-end'>
                <IconButton aria-label="edit" onClick={() => props.edit()}>
                    <EditRoundedIcon />
                </IconButton>
            </Box>
            <Box sx={{display: 'flex'}} justifyContent='flex-end'>
                <IconButton aria-label="delete" color='error' onClick={() => props.delete()}>
                    <DeleteRoundedIcon />
                </IconButton>
            </Box>
        </Stack>
    </Stack>
}

export default InvoiceItem