import React, {useEffect, useRef, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import useCallDataApi from "../../hooks/data";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {useSnackbar} from "notistack";
import locale from 'date-fns/locale/en-GB'
import {format} from "date-fns";
import DinoDataGrid from "../../components/DataGrid";
import DeliveryPeriodData from "../daily/DeliverPeriodData";
import {getFormData} from "../../utils/FormUtils";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import DinoDialog from "../../components/Dialog";
import DataGridWrapper from "../../components/DataGridWrapper";
import {createMap} from "../../utils/NumberUtils";
import {determineAddress, orderStatusMap} from "../../utils/utils";
import DataGridWithSearch from "../../components/DataGridWithSearch";


const Courier = () => {
    const [orders, setOrders] = useState([])
    const [tourOffices, setTourOffices] = useState({list: [], obj: {}});
    const [tourGuides, setTourGuides] = useState({list: [], obj: {}});
    const [couriers, setCouriers] = useState([]);

    const [isDeliveryPeriodDialogOpen, toggleDeliveryPeriodDialog] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [current, setCurrent] = useState(0);
    const [courier, setCourier] = useState(1);
    const [receivers, setReceivers] = useState([]);
    const [transmitters, setTransmitters] = useState([]);
    const [loading, setLoading] = useState(true);

    const {getData: getTourOffices} = useCallDataApi("tour-offices");
    const {getData: getTourGuides} = useCallDataApi("tour-guides");
    const {getData: getCouriers} = useCallDataApi("couriers");
    const {updateData: editOrder, postData} = useCallDataApi("orders");
    const {getData: getTransmitters} = useCallDataApi("transmitters");
    const {getData: getReceivers} = useCallDataApi("receivers");

    const deliveryForm = useRef(null);

    const {enqueueSnackbar} = useSnackbar();

    const getOrdersForDateRange = () => {
        const body = {
            delivery_date: format(new Date(deliveryDate), 'yyyy-MM-dd'),
            return_date: format(new Date(returnDate), 'yyyy-MM-dd'),
            courier: courier
        };
        postData('courier', body).then(r => setOrders(r))
    }

    const getTourOffice = (id) => tourOffices.find(t => (t.id === id))

    const getTourGuide = (id) => tourGuides.find(t => (t.id === id))

    const getCurrentOrder = (id) => orders.find(o => o.id === id)

    useEffect(() => {
        getOrdersForDateRange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryDate, returnDate, courier])

    useEffect(() => {
        const getData = async () => {
            const [c, t, g, tx, rx] = await Promise.all([
                getCouriers(),
                getTourOffices(),
                getTourGuides(),
                getTransmitters(),
                getReceivers()
            ])
            if (c) setCouriers(c)
            if (t) setTourOffices({list: t, obj: createMap(t)})
            if (g) setTourGuides({list: g, obj: createMap(g)})
            if (tx) setTransmitters(tx)
            if (rx) setReceivers(rx)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeDeliveryPeriod = (data) => {
        editOrder(current, data).then(editedOrder => {
            const editedOrders = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrders)
            enqueueSnackbar('Szerkesztve!', {variant: 'success'})
        })
    }

    const handleSetDeliveryPeriod = () => {
        let formData = getFormData(deliveryForm.current)
        changeDeliveryPeriod({order: current, ...formData})
    }

    const columns = [
        {
            field: "manage",
            headerName: "Kezelés",
            sortable: false,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<AvTimerIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleDeliveryPeriodDialog(true)
                    }}
                    label="Kiszállítási idő beállítása"
                    showInMenu={true}
                />
            ],
        },
        {field: "actual_delivery_date", headerName: "Kiszállítás", width: 150},
        {
            field: "delivery_courier",
            headerName: "Kiszállító futár",
            width: 140,
            valueGetter: p => couriers.find(c => c.id === p.row.delivery_courier)?.name || '-'
        },
        {
            field: "return_courier",
            headerName: "Visszaszállító futár",
            width: 140,
            valueGetter: p => couriers.find(c => c.id === p.row.return_courier)?.name || '-'
        },
        {field: "actual_return_date", headerName: "Visszaszállítás", width: 150},
        {field: "delivery_period", headerName: "Oda mikor", width: 50},
        {field: "return_period", headerName: "Vissza mikor", width: 50},
        {field: "order_number", headerName: "Rendelés szám", width: 140},
        {field: "comment", headerName: "Megjegyzés", width: 130},
        {field: "internal_comment", headerName: "Belsős megjegyzés", width: 130},
        {field: "order_status", headerName: "Állapot", width: 130, renderCell: p => orderStatusMap[p.row.order_status]},
        {field: "tour_type", headerName: "Program", width: 110},
        {field: "tour_office", headerName: "Megrendelő", width: 110, valueGetter: (params) => {
                const to = tourOffices.obj[params.row.tour_office]
                if (to) return to?.name
                return ''
            }},
        {field: "tour_code", headerName: "Megnevezés", width: 110},
        {field: "tour_guide", headerName: "Csoportkísérő", width: 110, valueGetter: (params) => {
                const tg = tourGuides.obj[params.row.tour_guide]
                if (tg) return `${tg?.last_name} ${tg?.first_name}`
                return ''
            }},
        {field: "delivery_date", type: "date", headerName: "Kiszállítás dátum", width: 200},
        {
            field: "delivery_time",
            type: "string",
            headerName: "Kiszállítás idő",
            width: 200,
            renderCell: (params) => (params.row['delivery_time']?.slice(0, 5))
        },
        {field: "days_before", headerName: "Előtte", width: 110},
        {field: "delivery_type", headerName: "Kiszállítás típusa", width: 110},
        {field: "delivery_address", headerName: "Kiszállítási cím", width: 250, valueGetter: p => determineAddress(p.row?.delivery_address)},
        {field: "delivery_format", headerName: "Delivery format", width: 110},
        {field: "return_date", type: "date", headerName: "Vissza dátum", width: 200},
        {
            field: "return_time",
            type: "string",
            headerName: "Vissza idő",
            width: 200,
            renderCell: (params) => (params.row['return_time']?.slice(0, 5))
        },
        {field: "days_after", headerName: "Később", width: 110},
        {field: "return_type", headerName: "Visszaszállítás típusa", width: 110},
        {field: "return_address", headerName: "Vissza cím", width: 250, valueGetter: p => determineAddress(p.row?.return_address)},
        {field: "returned_rx", headerName: "Visszérkezett RX", width: 110},
        {field: "returned_tx", headerName: "Visszaérkezett TX", width: 110},
        {
            field: "receiver",
            headerName: "Vevő",
            width: 110,
            valueGetter: p => receivers.find(r => r.id === p.row.receiver)?.name
        },
        {
            field: "transmitter",
            headerName: "Adó",
            width: 110,
            valueGetter: p => transmitters.find(t => t.id === p.row.transmitter)?.name
        },
        {field: "order_rx", headerName: "Rendelt RX", width: 110},
        {field: "order_tx", headerName: "Rendelt TX", width: 110},
        {field: "sent_rx", headerName: "Elküldött RX", width: 110},
        {field: "sent_tx", headerName: "Elküldött TX", width: 110},
        {field: "invoice", headerName: "Számla típusa", width: 110},
        {field: "invoice_number", headerName: "Számla azonosító", width: 110},
        {field: "currency", headerName: "Pénznem", width: 110},
        {field: "DHL_delivery_number", headerName: "Oda fuvarlevélszám", width: 110},
        {field: "DHL_return_number", headerName: "Vissza fuvarlevélszám", width: 110},
        {field: "DHL_order", headerName: "Oda felvételazonosító", width: 110},
        {field: "DHL_order_back", headerName: "Vissza felvételazonosító", width: 110},
        {field: "closed", headerName: "Lezárva", type: "boolean", width: 140}
    ]

    return (
        <div style={{height: "100vh"}}>
            <PersistentDrawerLeft title="Futárok">
                <Stack spacing={2} direction='row' marginBottom={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                        <DatePicker
                            label="Kiszállítás dátuma"
                            value={deliveryDate}
                            mask="____-__-__"
                            inputFormat="yyyy-MM-dd"
                            onChange={(newValue) => {
                                setDeliveryDate(newValue);
                            }}
                            InputAdornmentProps={{ position: 'start' }}
                            renderInput={(params) => <TextField id="delivery-date" InputAdornmentProps={{ position: 'start' }} name="delivery-date" {...params} />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                        <DatePicker
                            label="Visszaszállítás dátuma"
                            value={returnDate}
                            mask="____-__-__"
                            inputFormat="yyyy-MM-dd"
                            onChange={(newValue) => {
                                setReturnDate(newValue);
                            }}
                            InputAdornmentProps={{ position: 'start' }}
                            renderInput={(params) => <TextField id="return-date" InputAdornmentProps={{ position: 'start' }} name="return-date" {...params} />}
                        />
                    </LocalizationProvider>
                    <Box sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="courier-select-label">Futár</InputLabel>
                            <Select
                                labelId="courier-select-label"
                                id="courier-select"
                                value={courier}
                                label="Futár"
                                onChange={(event) => setCourier(event.target.value)}
                            >
                                {couriers?.map((c, i) => <MenuItem key={`courier_${i}`} value={c.id}>{c.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>

                <DataGridWithSearch name='courier' rows={orders} columns={columns}/>
                <Loading isLoading={loading}/>

            </PersistentDrawerLeft>

            <DinoDialog
                title="Kiszállítási idő beállítása"
                open={isDeliveryPeriodDialogOpen}
                handleClose={() => toggleDeliveryPeriodDialog(false)}
                actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                    <Button size='large' variant='outlined' onClick={() => toggleDeliveryPeriodDialog(false)}>Mégsem</Button>
                    <Button size='large' variant='outlined' color='success' onClick={() => {
                        handleSetDeliveryPeriod()
                        toggleDeliveryPeriodDialog(false)
                    }}>Hozzáadás</Button>
                </Stack>}>
                <DeliveryPeriodData reference={deliveryForm} order={getCurrentOrder(current)}/>
            </DinoDialog>

        </div>
    );
};

export default Courier;
