import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '80%',
    height: '200px'
}

const Map = ({children, center}) => {

    return <GoogleMap
        mapContainerStyle={containerStyle}
        center={center || {"lat":47.497912,"lng":19.040235}}
        zoom={children ? 15 : 10}
    >
        {children}
        <></>
    </GoogleMap>
}

export default Map