import React, {useEffect, useRef, useState} from "react";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingFrom";
import CustomTextField from "../../components/formValidation/CustomTextField";
import {countries} from "../../utils/countries";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Grid,
    IconButton,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import CustomAutocomplete from "../../components/formValidation/CustomAutocomplete";
import {validateRequired, validateVatNumber} from "../../components/formValidation/validators";
import DinoDialog from "../../components/Dialog";
import Button from "@mui/material/Button";
import useCallDataApi from "../../hooks/data";
import {useSnackbar} from "notistack";
import {cleanData} from "../../utils/FormUtils";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import NewContatctPersonData from "./NewContatctPersonData";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import {generateUUID} from "../../utils/utils";

const NewTourOfficeData = ({reference, obj}) => {
    const theme = useTheme()
    const [contactPersons, setContactPersons] = useState([]);
    const [current, setCurrent] = useState(1);
    const [isDeleteContactPersonDialogOpen, toggleDeleteContactPersonDialog] = useState(false)
    const [isEditContactPersonDialogOpen, toggleEditContactPersonDialog] = useState(false)
    const [isContactPersonDialogOpen, toggleContactPersonDialog] = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    const contactPersonForm = useRef(null)

    const {postData: postContactPerson, createData, updateData, deleteData} = useCallDataApi("contact-person");

    const getContactPerson = id => contactPersons.find(c => c?.id === id)

    const newContactPerson = (ContactPersonData) => {
        createData(ContactPersonData).then(newContactPerson => {
            setContactPersons(contactPerson => [...contactPerson, newContactPerson])
            enqueueSnackbar('Kapcsolattartó mentve!', {variant: 'success'})
        })
    }

    const editContactPerson = (contactData) => {
        updateData(current, contactData).then(editedContact => {
            contactPersons.map(c => c.id === current ? {...c, ...editedContact} : c)
            enqueueSnackbar('Kapcsolattartó szerkesztve!', {variant: 'success'})
        })
    }

    const deleteContactPerson = () => {
        deleteData(current).then(() => {
            setContactPersons(contactPersons.filter(contact => (contact.id !== current)))
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    useEffect(() => {
        if (obj) postContactPerson('get_for_tour_office', {tour_office_id: obj?.id}).then(c => setContactPersons(c))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    const [formData, setFormData] = useState({
        name: '' || obj?.name,
        short_name: '' || obj?.short_name,
        country: '' || obj?.country,
        zip_code: '' || obj?.zip_code,
        city: '' || obj?.city,
        address: '' || obj?.address,
        vat_number: '' || obj?.vat_number,
        is_company: '' || obj?.is_company,
    })

    const [errors, setErrors] = useState({
        name: false,
        short_name: false,
        country: false,
        zip_code: false,
        city: false,
        address: false,
        vat_number: false,
        is_company: false,
    })

    const validators = {
        name: [validateRequired],
        short_name: [validateRequired],
        country: [validateRequired],
        zip_code: [validateRequired],
        city: [validateRequired],
        address: [validateRequired],
        vat_number: [validateVatNumber],
        is_company: [],
    }

    const sendMail = (contact) => {
        if (contact?.email) window.open(`mailto:${contact?.email}`)
    }

    const callNumber = (contact) => {
        if (contact?.phone) window.open(`tel:${contact?.phone}`)
    }

    return <>
        <ErrorHandlingForm validators={validators} reference={reference} setErrors={setErrors} errors={errors}
                           data={formData} setData={setFormData}>
            <Stack spacing={2}>
                <Typography variant='caption' color={theme.palette.primary[500]}>Név*</Typography>
                <CustomTextField
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="name"
                    label='Név'/>
                <Typography variant='caption' color={theme.palette.primary[500]}>Rövid név*</Typography>
                <CustomTextField
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="short_name"
                    label='Rövid név'/>
                <Typography variant='caption' color={theme.palette.primary[500]}>Irányítószám*</Typography>
                <CustomTextField
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="zip_code"
                    label='Irányítószám'/>
                <Typography variant='caption' color={theme.palette.primary[500]}>Ország*</Typography>
                <CustomAutocomplete
                    options={countries}
                    label='Ország'
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 1, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.code}
                        </Box>
                    )}
                    name="country"
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                />
                <Typography variant='caption' color={theme.palette.primary[500]}>Város*</Typography>
                <CustomTextField
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="city"
                    label='Város'/>

                <Typography variant='caption' color={theme.palette.primary[500]}>Utca házszám*</Typography>
                <CustomTextField
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="address"
                    label='Utca, házszám'/>
                <Typography variant='caption' color={theme.palette.primary[500]}>Adószám</Typography>
                <CustomTextField
                    data={formData}
                    setData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    name="vat_number"
                    label='Adószám'/>

                <Typography variant='caption' color={theme.palette.primary[500]}>Cég</Typography>
                <Box>
                    <Checkbox onChange={e => setFormData({...formData, is_company: e.target.checked})}
                              checked={formData?.is_company}/>
                </Box>

                {obj && <Button onClick={() => toggleContactPersonDialog(true)} variant='outlined' color='success' endIcon={<AddRoundedIcon/>}>Kapcsolattartó hozzáadása</Button>}
            </Stack>
        </ErrorHandlingForm>

        <Grid style={{marginTop: '10px'}} container spacing={2}>
            {contactPersons.map(contact => <Grid key={contact?.id || generateUUID()} item xs={7} lg={6}>
                <Card sx={{maxWidth: 345}}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            {contact?.first_name} {contact?.last_name}
                        </Typography>
                        <Stack spacing={1}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <IconButton color='success' onClick={() => callNumber(contact)}><PhoneIcon/></IconButton>
                                <Typography variant="body2" color="text.secondary">{contact?.phone ? contact?.phone : '-'}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <IconButton color='success' onClick={() => sendMail(contact)}><EmailIcon/></IconButton>
                                <Typography variant="body2" color="text.secondary">{contact?.email ? contact?.email : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" color="success" onClick={() => {
                            setCurrent(contact.id);
                            toggleEditContactPersonDialog(true)
                        }}>Szerkesztés</Button>
                        <Button size="small" variant="outlined" color="error" onClick={() => {
                            setCurrent(contact.id);
                            toggleDeleteContactPersonDialog(true)
                        }}>Törlés</Button>
                    </CardActions>
                </Card>
            </Grid>)}
        </Grid>

        <DinoDialog
            title="Új kapcsolattartó"
            open={isContactPersonDialogOpen}
            handleClose={() => toggleContactPersonDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleContactPersonDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    const data = contactPersonForm.current.getData()
                    if (Object.entries(data).length > 0) {
                        toggleContactPersonDialog(false)
                        newContactPerson(data)
                    }
                }}>Hozzáadás</Button>
            </Stack>}>
            <NewContatctPersonData reference={contactPersonForm}/>
        </DinoDialog>

        <DinoDialog
            title="Kapcsolattartó szerkesztése"
            open={isEditContactPersonDialogOpen}
            handleClose={() => toggleEditContactPersonDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined'
                        onClick={() => toggleEditContactPersonDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    const data = contactPersonForm.current.getData()
                    if (Object.entries(data).length > 0) {
                        toggleEditContactPersonDialog(false)
                        editContactPerson(data)
                    }
                }}>Szerkesztés</Button>
            </Stack>}>
            <NewContatctPersonData reference={contactPersonForm} obj={getContactPerson(current)}/>
        </DinoDialog>

        <DinoDialog
            handleClose={() => toggleDeleteContactPersonDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined'
                        onClick={() => toggleDeleteContactPersonDialog(false)}>Mégsem</Button>
                <Button size='large' variant='contained' color='error' onClick={() => {
                    toggleDeleteContactPersonDialog(false)
                    deleteContactPerson()
                }}>Törlés</Button>
            </Stack>}
            open={isDeleteContactPersonDialogOpen}
            title={`Biztosan törölni akarja: ${getContactPerson(current)?.first_name} ${getContactPerson(current)?.last_name}?`}
            subtitle='Minden kapcsolódó adat elvész.'/>
    </>

}

export default NewTourOfficeData