import PersistentDrawerLeft from "../../components/NewDrawer";
import {Button, Divider, Stack, TextField, Typography} from "@mui/material";
import {format} from "date-fns";
import {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import DinoDialog from "../../components/Dialog";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {useSnackbar} from 'notistack';

const OrderReturn = () => {
    const {postData, updateData} = useCallDataApi('orders')
    const today = new Date()
    const translate = {
        'Monday': 'Hétfő',
        'Tuesday': 'Kedd',
        'Wednesday': 'Szerda',
        'Thursday': 'Csütörtök',
        'Friday': 'Péntek',
        'Saturday': 'Szombat',
        'Sunday': 'Vasárnap'
    }
    const [loading, setLoading] = useState(false)
    const [slug, setSlug] = useState('')
    const [selectedOrder, setSelectedOrder] = useState({})
    const [returnOrder, setReturnOrder] = useState({
        transmitters: 0,
        receivers: 0
    })
    const [returnDialogOpen, setReturnDialogOpen] = useState(false)

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setReturnOrder({
            receivers: selectedOrder?.sent_rx,
            transmitters: selectedOrder?.sent_tx
        })
    }, [selectedOrder])

    const extractSlugFromUrl = (url) => {
        // Regular expression to match the slug in the URL
        const regex = /\/core\/waybill\/(.*?)$/;

        // Execute the regular expression on the URL
        const match = url.match(regex);

        // If a match is found, return the captured group (slug)
        if (match && match[1]) {
            return match[1];
        } else {
            throw new Error('Nem jó QR kód!')
        }
    }

    const returnCurrentOrder = () => {
        updateData(selectedOrder?.id, {returned_rx: returnOrder?.receivers, returned_tx: returnOrder?.transmitters, order_status: 'Returned'}).then(editedOrder => {
            enqueueSnackbar('Megrendelés visszaérkeztetve!', {variant: 'success'})
        })
    }

    const getOrder = () => {
        setLoading(true)
        const today = new Date()
        try {
            postData('get_order_number_by_slug', {slug: extractSlugFromUrl(slug)})
                .then(r => {
                    if (r?.actual_return_date !== format(today, 'yyyy-MM-dd')) enqueueSnackbar('Nem ma kellene visszaszállítani a rendelést!', {variant: 'warning'})
                    if (r?.order_status === 'Returned') enqueueSnackbar('A rendelést már visszaérkeztették, biztosan felülírod?', {variant: 'warning'})
                    setSelectedOrder(r)
                    setReturnDialogOpen(true)
                })
                .finally(() => setLoading(false))
        } catch (e) {
            setLoading(false)
            enqueueSnackbar(e.message, {variant: 'error'})
        }
    }

    return <PersistentDrawerLeft title='Rendelés visszaérkeztetés'>
        <Stack direction='column' spacing={2}>
            <Typography variant='h4'>Mai dátum: {format(today, 'yyyy-MM-dd')} - ({translate[format(today, 'EEEE')]})</Typography>
            <form onSubmit={e => {
                e.preventDefault()
                getOrder()
                setSlug('')
            }}>
                <TextField value={slug} onChange={e => setSlug(e.target.value)} label='Szkenneld be a rendelés QR kódját' sx={{minWidth: '300px'}}/>
            </form>
        </Stack>
        <Loading isLoading={loading}/>

        <DinoDialog
            open={returnDialogOpen}
            handleClose={() => setReturnDialogOpen(false)}
            title={`${selectedOrder?.order_number} visszaérkeztetése`}
            subtitle='Ellenőrizd, hogy az összes adó és vevő készülék vissza lett-e küldve!'
            actions={<Stack direction='row' spacing={2}>
                <Button onClick={() => setReturnDialogOpen(false)} variant='outlined'>Mégsem</Button>
                <Button onClick={() => {
                    setReturnDialogOpen(false)
                    returnCurrentOrder()
                }} color='success' variant='outlined' endIcon={<SendRoundedIcon/>}>Visszaérkeztetés</Button>
            </Stack>}>
            <Stack spacing={2}>
                <Stack spacing={2}>
                    <Typography variant='h6'>Elküldött eszközök száma:</Typography>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='body'>Adók száma: {selectedOrder?.sent_tx} {selectedOrder?.color || '(Nem volt megadva szín)'}</Typography>
                        <Typography variant='body'>Vevők száma: {selectedOrder?.sent_rx} {selectedOrder?.color || '(Nem volt megadva szín)'}</Typography>
                    </Stack>
                </Stack>
                <Divider/>
                <Stack spacing={2}>
                    <Typography variant='h6'>Visszahozott eszközök száma:</Typography>
                    <Stack direction='row' justifyContent='space-between'>
                        <TextField value={returnOrder?.transmitters} onChange={e => setReturnOrder(e.target.value)} label='Adók száma'/>
                        <TextField value={returnOrder?.receivers} onChange={e => setReturnOrder(e.target.value)} label='Vevők száma'/>
                    </Stack>
                </Stack>
            </Stack>
        </DinoDialog>
    </PersistentDrawerLeft>
}

export default OrderReturn