import React, {useImperativeHandle, useRef} from 'react';
import {useSnackbar} from "notistack";

const ErrorHandlingForm = ({reference, children, errors, setErrors, data, setData, validators, customValidators=[], ...rest}) => {
    const {enqueueSnackbar} = useSnackbar();
    const formRef = useRef(null)
    const getData = () => {
        let formValid = true;
        const newErrors = {...errors}
        for (const fieldName in data) {
            const value = data[fieldName]
            for (let validator of validators[fieldName]) {
                const {valid, error} = validator(value)
                if (!valid) {
                    newErrors[fieldName] = error
                    formValid = false
                }
            }
        }
        for (let customValidator of customValidators)  {
            const {valid, error} = customValidator(data)
            console.log(`valid: ${valid}`)
            if (!valid) {
                enqueueSnackbar(error, {variant: 'error'})
                formValid = false
                break
            }
        }
        setErrors(newErrors)

        if (formValid) {
            return data
        } else {
            formRef.current.scrollIntoView({ behavior: 'smooth' })
            return null
        }
    }

    useImperativeHandle(reference, () => ({
        getData
    }))

    return <form {...rest} ref={formRef}>
        {children}
    </form>
}

export default ErrorHandlingForm;
