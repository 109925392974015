import {
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import {DatePicker, DateTimePicker, LocalizationProvider, TimePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import locale from "date-fns/locale/en-GB";

const ExpressionData = ({reference, report, expression}) => {
    const [fields, setFields] = useState([])
    const [field, setField] = useState('')
    const [operator, setOperator] = useState('')
    const {getData, postData} = useCallDataApi('report')
    const [previouslySelected, setPreviouslySelected] = useState('')
    const [selected, setSelected] = useState('')
    const [checked, setChecked] = useState(true)

    useEffect(() => {
        getData(`${report?.id}/get_fields_query`).then(r => {
            setFields(r)
            if (expression) processExpression(expression, r).then(r => console.log('Processed'))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRelatedFieldOnEdit = async (fil, varList, count) => {
        const find = fil?.find(f => f.name === varList[count]);
        if (varList.length - 1 === count) {
            return {chosenField: find, allField: fil}
        }
        const resp = await postData('get_related_fields_query', {model_name: find?.to ? find?.to : find?.name})
        return await getRelatedFieldOnEdit(resp, varList, count + 1)
    }

    const processExpression = async (expression, fieldList) => {
        const key = Object.keys(expression)[0]
        const val = Object.values(expression)[0]?.toString()
        const regex = /__(lte|gte|gt|lt|contains|endswith|isnull)?$/; // This regex matches the operator at the end
        const match = key.match(regex);
        let extractedOperator = ''
        let variableNames = key.split('__')
        if (match) {
            extractedOperator = '__' + match[1]
            const parts = key.split(regex);
            variableNames = parts[0].split('__')
        }
        if (variableNames.length === 1) {
            // Amikor simán csak választott a mezők közül
            // console.log('Amikor simán csak választott a mezők közül')
            const chosenField = fieldList.find(f => f.name === variableNames[0])
            setField(chosenField)
            setOperator(extractedOperator)
            setSelected(val)
        } else {
            // Amikor idegen kulcsok is vannak
            // console.log('Amikor idegen kulcsok is vannak')
            const {chosenField, allField} = await getRelatedFieldOnEdit(fieldList, variableNames, 0)
            setFields(allField)
            setField(chosenField)
            setOperator(extractedOperator)
            setSelected(val)
            variableNames.pop()
            setPreviouslySelected(variableNames.join('__'))
        }
    }

    const handleFieldChange = (event) => {
        setField(event.target.value);
    }

    const handleOperatorChange = (event) => {
        setOperator(event.target.value);
    }

    const manageForeignKeyClick = (name, type, to) => {
        if (type === 'ForeignKey' || type === 'OneToOneField') {
            setPreviouslySelected(prevState => {
                if (prevState !== '') return prevState + '__' + name
                else return name
            })
            postData('get_related_fields_query', {model_name: to ? to : name}).then(r => setFields(r))
        }
    }

    const fieldMap = () => {
        const operatorObject = typeOperatorMap[field?.type]?.find(op => op.value === operator)
        switch (operatorObject?.type || field?.type) {
            case 'IntegerField':
                return <TextField type='number' name='value' label='Érték' value={selected} onChange={(val) => setSelected(val.target.value)}/>
            case 'BigAutoField':
                return <TextField type='number' name='value' label='Érték' value={selected} onChange={(val) => setSelected(val.target.value)}/>
            case 'FloatField':
                return <TextField type='number' name='value' label='Érték' value={selected} onChange={(val) => setSelected(val.target.value)}/>
            case 'PositiveIntegerField':
                return <TextField type='number' name='value' label='Érték' value={selected} onChange={(val) => setSelected(val.target.value)}/>
            case 'DateField':
                return <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <DatePicker
                        label="Date picker"
                        mask="____-__-__"
                        onChange={(val) => setSelected(val)}
                        value={selected}
                        inputFormat="yyyy-MM-dd"
                        InputAdornmentProps={{ position: 'start' }}
                        renderInput={(params) => <TextField id="value" InputAdornmentProps={{ position: 'start' }} name="value" {...params} />}
                    />
                </LocalizationProvider>
            case 'DateTimeField':
                return <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <DateTimePicker
                        label="Date&Time picker"
                        value={selected}
                        mask="__-__-____ __:__"
                        inputFormat="yyyy-MM-dd hh:mm"
                        onChange={(val) => setSelected(val)}
                        renderInput={(params) => <TextField id="value" name="value" {...params} />}
                    />
                </LocalizationProvider>
            case 'TimeField':
                return <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <TimePicker
                        label="Time"
                        value={selected}
                        onChange={(val) => setSelected(val)}
                        renderInput={(params) => <TextField id="value" name="value"  {...params} />}
                    />
                </LocalizationProvider>
            case 'BooleanField':
                return <>
                    <input type="hidden" name="logicalfield-value" value={checked} />
                    <FormControlLabel control={<Checkbox onChange={() => setChecked(p => !p)} checked={checked} />} label="Érték" />
                </>
            default:
                return <TextField name='value' value={selected} onChange={(val) => setSelected(val.target.value)} label='Érték default'/>
        }
    }

    const typeOperatorMap = {
        "CharField": [{show: "egyenlő", value: ""}, {show: "pontosan ez", value: "__exact"}, {
            show: "tartalmazza",
            value: "__contains"
        }, {
            show: "ezzel kezdődik",
            value: "__startswith"
        }, {show: "ezzel végződik", value: "__endswith"}, {
            show: "értéke null",
            value: "__isnull",
            type: 'BooleanField'
        }],
        "BigAutoField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "PositiveIntegerField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "IntegerField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "FloatField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "BooleanField": [{show: "egyenlő", value: ""}],
        "DateField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "DateTimeField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "TimeField": [{show: "egyenlő", value: ""}, {
            show: "nagyobb mint",
            value: "__gt"
        }, {show: "nagyobb vagy egyenlő mint", value: "__gte"}, {
            show: "kisebb mint",
            value: "__lt"
        }, {show: "kisebb vagy egyenlő mint", value: "__lte"}],
        "EmailField": [{show: "egyenlő", value: ""}, {
            show: "tartalmazza",
            value: "__contains"
        }, {show: "ezzel kezdődik", value: "__startswith"}, {show: "ezzel végződik", value: "__endswith"}],
        "URLField": [{show: "egyenlő", value: ""}, {show: "tartalmazza", value: "__contains"}, {
            show: "ezzel kezdődik",
            value: "__startswith"
        }, {show: "ezzel végződik", value: "__endswith"}],
        "ForeignKey": [{show: "egyenlő", value: ""}],
        "ManyToManyField": [{show: "egyenlő", value: ""}],
        "TextField": [{show: "egyenlő", value: ""}, {show: "tartalmazza", value: "__contains"}, {
            show: "ezzel kezdődik",
            value: "__startswith"
        }, {show: "ezzel végződik", value: "__endswith"}, {
            show: "értéke null",
            value: "__isnull",
            type: 'BooleanField'
        }],
        "ImageField": [{show: "egyenlő", value: ""}]
    }


    return <form ref={reference}>
        <Stack spacing={2}>
            <input type="hidden" value={previouslySelected} name='prefix'/>
            <Typography variant='p'>Mező {previouslySelected}</Typography>
            <FormControl fullWidth>
                <Select
                    id="field-select"
                    value={field}
                    label="Mező"
                    onChange={handleFieldChange}
                >
                    {fields?.map((f, i) => <MenuItem onClick={() => manageForeignKeyClick(f.name, f.type, f.to)} key={`f_${i}_${f.name}`} value={f}>{f.verbose_name} - {f.type}</MenuItem>)}
                </Select>
            </FormControl>
            <input type="hidden" value={field?.name || ''} name='field'/>
            <Typography variant='p'>Operátor</Typography>
            <FormControl fullWidth>
                <Select
                    id="operator-select"
                    value={operator}
                    label="Operátor"
                    name='operator'
                    onChange={handleOperatorChange}
                >
                    {typeOperatorMap[field?.type]?.map((f, i) => <MenuItem key={`f_${f.show}_${i}`} value={f.value}>{f.show}</MenuItem>)}
                </Select>
            </FormControl>
            <Typography variant='p'>Érték</Typography>
            {fieldMap()}
        </Stack>
    </form>
}

export default ExpressionData