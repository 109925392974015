import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Avatar, Chip, Stack, Typography} from "@mui/material";


const ControlledEditor = ({title, editorState, setEditorState}) => {

    const messageVariableStrategy = (contentBlock, callback, contentState) => {
        const regex = /\{\{([^}]+)\}\}/g
        const text = contentBlock.getText();
        let matchArr, start;
        while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    }

    const messageVariableMap = {
        '{{ order.order_number }}': 'Megrendelésszaám',
        '{{order.ordered_by}}': 'Megrendelő'
    }

    const MessageVariable = (props) => {
        // return <Chip key={generateRandomNumber()} color="success" label={messageVariableMap[props.children[0].props.text]}/>
        return <span className='messageVariable'>{props.children}</span>;
    }

    return <Stack spacing={2}>
        <Typography variant='h4'>{title}</Typography>
        <div style={{background: 'white', borderRadius: '2px'}}>
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={setEditorState}
                customDecorators={[
                    {
                        strategy: messageVariableStrategy,
                        component: MessageVariable,
                    },
                ]}
            />
        </div>
    </Stack>
};

export default ControlledEditor;
