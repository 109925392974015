import React from 'react';
import {Card, CardContent, Typography, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import {thousandSeparator} from "../../utils/NumberUtils";

const CouponCodeDetail = ({ handle, selected, coupon }) => {
    const { name, valid_thru, percent, minus, allowed_users } = coupon;
    const theme = useTheme()
    return (
        <Card onClick={handle} sx={{background: selected ? theme.palette.primary.dark : 'inherit', cursor: handle ? 'pointer': 'inherit'}}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {name}
                </Typography>
                <Typography color="textSecondary">
                    Érvényes eddig: {valid_thru}
                </Typography>
                {percent !== null && (
                    <Typography variant="body2" color="textSecondary">
                        Kedvezmény: -{percent}%
                    </Typography>
                )}
                {minus !== null && percent === null && (
                    <Typography variant="body2" color="textSecondary">
                        Kedvezmény: -{thousandSeparator(minus)}
                    </Typography>
                )}
                {allowed_users?.length > 0 ? (
                    <Typography variant="body2" color="textSecondary">
                        Jogosult felhasználók: {allowed_users.join(', ')}
                    </Typography>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        Jogosult felhasználók: Minden felhasználó
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

CouponCodeDetail.propTypes = {
    coupon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        valid_thru: PropTypes.string.isRequired,
        percent: PropTypes.number,
        minus: PropTypes.number,
        allowed_users: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default CouponCodeDetail;
